import React from "react";
import { Redirect } from "react-router-dom";


// User profile
import UserProfile from "../pages/Authentication/UserProfile";


// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import CorContactInfo from "../pages/General/CorContactInfo/index";
import CorContactInfoDetails from "../pages/General/CorContactInfo/CorContactInfoDetail";
import GeneralDashboard from "../pages/General/index";
import ProfileDetail from "../pages/General/Profile/index";
import ManageMom from "../pages/General/CorContactInfo/ManageMom";
import CorLeadMomList from "../pages/General/CorContactInfo/CorLeadMomList";
import Bureauhelpdata from "../pages/General/BureauHelp/Bureauhelpdata";
//import CorporateServiceplan from "../pages/General/CorporateServiceplan/CorporateServiceplan";
import BureauHelp from "../pages/General/CorContactInfo/BureauHelp";
import BureauHelpLink from "../pages/General/CorContactInfo/BureauHelpLink";
import Services from "../pages/General/Services/index";
import ServiceDetail from "../pages/General/Services/ServiceDetail";
import ServiceDetailType from "../pages/General/Services/ServiceDetailType";
import CorporateClientServiceActivities from "../pages/General/Services/CorporateClientServiceActivities"
import CorporateClientServiceActivities1 from "../pages/General/Services/CorporateClientServiceActivities1"
import SerPackageDetail from "../pages/General/Services/SerPackageDetail";
import ServiceMonthlyDues from "../pages/General/Services/ServiceMonthlyDues";
import ManageInformation from "../pages/General/ManageInformation/index";
import ManageInfo from "../pages/General/ManageInformation/ManageInfo";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
 
// this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  
 
  { path: "/CorContactInfo", component: CorContactInfo },
  { path: "/CorContactInfo/CorContactInfoDetail/:id", component: CorContactInfoDetails },

  { path: "/CorContactInfo/ManageMom/:id", component: ManageMom },

  { path: "/CorContactInfo/ManageMom/:id", component: ManageMom },

  { path: "/CorContactInfo/BureauHelp", component: BureauHelp },
  { path: "/CorContactInfo/BureauHelpLink", component: BureauHelpLink },
  { path: "/Services", component: Services },
  { path: "/Services/ServiceDetail", component: ServiceDetail },
  { path: "/Services/ServiceDetailType", component: ServiceDetailType },
  { path: "/CorporateClientServiceActivities", component: CorporateClientServiceActivities },
  { path: "/CorporateClientServiceActivities1", component: CorporateClientServiceActivities1 },

  { path: "/Services/SerPackageDetail/:id", component: SerPackageDetail }, 
  { path: "/ServiceMonthlyDues", component: ServiceMonthlyDues }, 
  { path: "/CorContactInfo/CorLeadMomList", component: CorLeadMomList },
  { path: "/Bureauhelpdata", component: Bureauhelpdata },
  { path: "/GeneralDashboard", component: GeneralDashboard },
  { path: "/Profile", component: ProfileDetail },
 // { path: "/CorporateServiceplan", component: CorporateServiceplan },
  { path: "/CorContactInfo", component: CorContactInfo },
  { path: "/ManageInformation", component: ManageInformation },
  { path: "/ManageInfo", component: ManageInfo },
  
  
];

const publicRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },


//   { path: "/CorLeads/Editcorleads/:id", component: Editcorleads },
//  // { path: "/CorLeads/Editcorleads/:id", component: Editcorleads },

 




];

export { authProtectedRoutes, publicRoutes };