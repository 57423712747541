import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import maincontactimage from "../../../assets/images/users/avatar-1.jpg";
import axios from 'axios'

const ProfileIndex = () => {
  const [CorporateDetails, setCorporateDetails] = useState([]);
  const [LoginType, setLoginType] = useState('');
  const [CorporateDetail, setCorporateDetail] = useState([]);

  useEffect(() => {
    let VLoginType = localStorage.getItem("LoginType");
    setLoginType(VLoginType);
    getCorporateContactInfo();
    getCorporateDetail();
  }, []);

  const getCorporateContactInfo = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");
    let VCorporateleadContactId = localStorage.getItem("CorporateleadContactId");
    let VLoginType = localStorage.getItem("LoginType");
    if (VLoginType == "MainLogin") {
      const CorporateData = {
        CorLeadId: VCorporateleadId,
        CorInformationLeadId: ""
      }
      axios.post(global.APIURL + '/CorporateContactInfo', CorporateData)
        .then(res => {
          setCorporateDetails(res.data);
        });
    }
    else if (VLoginType == "SubLogin") {
      const CorporateData = {
        CorLeadId: "",
        CorInformationLeadId: VCorporateleadContactId
      }
      axios.post(global.APIURL + '/CorporateContactInfo', CorporateData)
        .then(res => {
          setCorporateDetails(res.data);
        });
    }
    else {
      setCorporateDetails([]);
    }
  }

  const getCorporateDetail = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const CorporateData = {
      Id: VCorporateleadId,
    }
    axios.post(global.APIURL + '/CorporateDetail', CorporateData)
      .then(res => {
        setCorporateDetail(res.data);
      });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Corporate Information</p>
                  {CorporateDetail?.map((item, idx) => (
                    <div className="ciboxmain" key={idx}>
                      <div className="mainciboxgride2">
                        <div >
                          <p className="ciboxtitlegrid">Network Type</p>
                          <p className="mainciboxgriddescription">{item.ChannelType}</p>
                        </div>
                        <div >
                          <p className="ciboxtitlegrid">Corporate</p>
                          <p className="mainciboxgriddescription">{item.ChannelMaster}</p>
                        </div>
                        <div >
                          <p className="ciboxtitlegrid">Email Address</p>
                          <p className="mainciboxgriddescription">{item.EmailAddress}</p>
                        </div>
                        <div>
                          <p className="ciboxtitlegrid">Branch Code</p>
                          <p className="mainciboxgriddescription">{item.Branchcode}</p>
                        </div>
                      </div>

                      <div className="mainciboxgride2">

                        <div>
                          <p className="ciboxtitlegrid">Corporate Status</p>
                          <p className="mainciboxgriddescription">{item.CorporateStatus}</p>
                        </div>
                        <div>
                          <p className="ciboxtitlegrid">Registered Date</p>
                          <p className="mainciboxgriddescription">{item.RegisterDate}</p>
                        </div>
                        <div>
                          <p className="ciboxtitlegrid">Converted Date</p>
                          <p className="mainciboxgriddescription">{item.ConvertDate}</p>
                        </div>
                        <div>
                          <p className="ciboxtitlegrid">Closed Date</p>
                          <p className="mainciboxgriddescription">{item.CloseDate}</p>
                        </div>
                      </div>
                      <div className="mainaddressboxci">
                        <div className="w-100">
                          <p className="ciboxtitlegrid">Address</p>
                          <p className="mainciboxgriddescription">{item.Address}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">{LoginType == 'MainLogin' ? 'Corporate Contact' : 'Basic Information'}</p>
                  {LoginType == 'MainLogin' ?
                    <div className="row mt-4">
                      {CorporateDetails.map((item, idx) => (
                        <div className="col-md-3" key={idx}>
                          <div className="ccboxmaindashb">
                            <div className="d-flex">
                              <div className="ccboxmaindashbimgdiv">
                                <img src={maincontactimage}></img>

                              </div>
                              <div>

                                <p className="ciboxtitlegrid mb-1">{item.Name}</p>
                                {item.Designation != '' ?
                                  <div className="d-flex ccmaildetails">
                                    <div>
                                      <i className="bx bxs-graduation"></i>
                                    </div>
                                    <div>
                                      <p className="mb-0">{item.Designation}</p>
                                    </div>
                                  </div>
                                  : ''}
                                {item.Mobilenumber != '' ?
                                  <div className="d-flex ccdetailscall mb-1">
                                    <div>
                                      <i className="las la-phone-volume "></i>
                                    </div>
                                    <div>
                                      <p className="mb-0">{item.Mobilenumber}</p>
                                    </div>
                                  </div> : ''}
                                {item.Email != '' ?
                                  <div className="d-flex ccmaildetails">
                                    <div>
                                      <i className="las la-envelope  "></i>
                                    </div>
                                    <div>
                                      <p className="mb-0">{item.Email}</p>
                                    </div>
                                  </div>
                                  : ''}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div> :
                    <Col md="12" className="mb-2">
                      <Card className="mini-stats-wid ">
                        <CardBody>
                          <Row className="justify-content-between">
                            <Col className="col-lg-4 mb-0">
                              <CardTitle className="mt-2">Basic Information</CardTitle>
                            </Col>
                            <hr />
                          </Row>
                          <Row className="justify-content-between">
                            <hr />
                          </Row>
                          <Col className="col-lg-12 mb-2">
                            {CorporateDetails?.map((item, idx) => (
                              <Row className="mb-2" key={idx}>
                                <Col className="col-lg-3 col-md-4 col-12 mt-2" >
                                  <label className="form-label">Name</label>
                                  <Input type="text" className="form-control" readOnly defaultValue={item.Name} />
                                </Col>
                                <Col className="col-lg-3 col-md-4 col-12 mt-2">
                                  <label className="form-label">Email Address</label>
                                  <Input type="text" className="form-control" readOnly defaultValue={item.Email} />
                                </Col>
                                <Col className="col-lg-3 col-md-4 col-12 mt-2">
                                  <label className="form-label">Contact Number</label>
                                  <Input type="text" className="form-control" readOnly defaultValue={item.Mobilenumber} />
                                </Col>
                                <Col className="col-lg-3 col-md-12 col-12 mt-2">
                                  <label className="form-label">Designation</label>
                                  <Input type="text" className="form-control" readOnly defaultValue={item.Designation} />
                                </Col>
                              </Row>
                            ))}

                          </Col>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  );

}

export default ProfileIndex;
