import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import maincontactimage from "../../assets/images/users/avatar-1.jpg";
import totalleads from "../../assets/images/dashboardimg/totalleads.png";
import axios from 'axios'

const Dashboard = () => {
  const [FinancialYearList, setFinancialYearList] = useState([]);
  const [UploadMonthList, setUploadMonthList] = useState([]);
  const [BureauList, setBureauList] = useState([]);
  const [CorporateDetails, setCorporateDetails] = useState([]);
  const [CorporateMeetingList, setCorporateMeetingList] = useState([]);
  const [LoginType, setLoginType] = useState('');
  const [CorporateServicesPackageList, setCorporateServicesPackageList] = useState([]);
  const [CorporateDetail, setCorporateDetail] = useState([]);
  const [CorporateServicesPackagePaymentList, setCorporateServicesPackagePaymentList] = useState([]);

  const [ServiceStatusList, setServiceStatusList] = useState([]);
  const [CorporateServiceUploadReportStepList, setCorporateServiceUploadReportStepList] = useState([]);

  const MonthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const [CurrentUploadMonth, setCurrentUploadMonth] = useState('');

  const [CorporateServiceUploadReportStepFileList, setCorporateServiceUploadReportStepFileList] = useState([]);


  const [TotalReportUpload, setTotalReportUpload] = useState(0);
  const [ConsumerReportUpload, setConsumerReportUpload] = useState(0);
  const [CommercialReportUpload, setCommercialReportUpload] = useState(0);
  const [MicroFinanceReportUpload, setMicroFinanceReportUpload] = useState(0);

  const [TodayTotalReportUpload, setTodayTotalReportUpload] = useState(0);
  const [TodayConsumerReportUpload, setTodayConsumerReportUpload] = useState(0);
  const [TodayCommercialReportUpload, setTodayCommercialReportUpload] = useState(0);
  const [TodayMicroFinanceReportUpload, setTodayMicroFinanceReportUpload] = useState(0);

  const [MonthlyTotalReportUpload, setMonthlyTotalReportUpload] = useState(0);
  const [MonthlyConsumerReportUpload, setMonthlyConsumerReportUpload] = useState(0);
  const [MonthlyCommercialReportUpload, setMonthlyCommercialReportUpload] = useState(0);
  const [MonthlyMicroFinanceReportUpload, setMonthlyMicroFinanceReportUpload] = useState(0);

  useEffect(() => {
    var totalRent = 55.66;
    var inWords = function (totalRent) {
      //console.log(totalRent);
      var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
      var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
      var number = parseFloat(totalRent).toFixed(2).split(".");
      var num = parseInt(number[0]);
      var digit = parseInt(number[1]);
      //console.log(num);
      if ((num.toString()).length > 9) return 'overflow';
      var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      var d = ('00' + digit).substr(-2).match(/^(\d{2})$/);;
      if (!n) return; var str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
      str += (n[5] != 0) ? (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'Rupee ' : '';
      str += (d[1] != 0) ? ((str != '') ? "and " : '') + (a[Number(d[1])] || b[d[1][0]] + ' ' + a[d[1][1]]) + 'Paise ' : 'Only!';
      console.log(str);
      return str;
    }
    console.log(inWords);
    let VLoginType = localStorage.getItem("LoginType");
    var today = new Date();
    var CurrentYear = today.getFullYear().toString();
    var VUploadCurrentMonthTitle = MonthNames[today.getMonth()] + '-' + CurrentYear;
    setCurrentUploadMonth(VUploadCurrentMonthTitle);
    getDashboardDropdownList();
    getDashboardCount();
    getServiceStatusList();
    getCorporateServiceUploadReportStepList();
    setLoginType(VLoginType);
    getCorporateContactInfo();
    getClientConvertedList();
    getCorLeadMomList();
    getCorporateServicesPackageList();
    getCorporateDetail();
    getCorporateServicesPackagepaymentList();
  }, []);

  const getDashboardDropdownList = async () => {

    const FiltrItem = {
      FinancialYearId: 0
    }
    await axios.post(global.APIURL + '/CorporateDashboardDropdownList', FiltrItem)
      .then(res => {
        setFinancialYearList(res.data.FinancialYearList);
        setUploadMonthList(res.data.UploadMonthList);
        setBureauList(res.data.BureauList);
      });
  };


  const getDashboardCount = async () => {

    const FiltrItem = {
      CorporateLeadId: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
      CorporateLeadUserId: parseInt(localStorage.getItem("CorporateleadContactId") == null ? "0" : localStorage.getItem("CorporateleadContactId") == "" ? "0" : localStorage.getItem("CorporateleadContactId")),
      FinancialYearId: parseInt(localStorage.getItem("FinancialYearId") == null ? "0" : localStorage.getItem("FinancialYearId") == "" ? "0" : localStorage.getItem("FinancialYearId"))
    }
    await axios.post(global.APIURL + '/CorporateDashboardCount', FiltrItem)
      .then(res => {
        setTotalReportUpload(res.data[0].TotalReportUpload);
        setConsumerReportUpload(res.data[0].ConsumerReportUpload);
        setCommercialReportUpload(res.data[0].CommercialReportUpload);
        setMicroFinanceReportUpload(res.data[0].MicroFinanceReportUpload);
        setTodayTotalReportUpload(res.data[0].TodayTotalReportUpload);
        setTodayConsumerReportUpload(res.data[0].TodayConsumerReportUpload);
        setTodayCommercialReportUpload(res.data[0].TodayCommercialReportUpload);
        setTodayMicroFinanceReportUpload(res.data[0].TodayMicroFinanceReportUpload);
        setMonthlyTotalReportUpload(res.data[0].MonthlyTotalReportUpload);
        setMonthlyConsumerReportUpload(res.data[0].MonthlyConsumerReportUpload);
        setMonthlyCommercialReportUpload(res.data[0].MonthlyCommercialReportUpload);
        setMonthlyMicroFinanceReportUpload(res.data[0].MonthlyMicroFinanceReportUpload);
      });
  };

  const getServiceStatusList = async () => {
    await axios.post(global.APIURL + '/ServiceStatusList')
      .then(res => {
        setServiceStatusList(res.data);
      });
  }

  const getCorporateServiceUploadReportStepList = async () => {

    const FiltrItem = {
      CorporateLeadId: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
      CorporateLeadUserId: parseInt(localStorage.getItem("CorporateleadContactId") == null ? "0" : localStorage.getItem("CorporateleadContactId") == "" ? "0" : localStorage.getItem("CorporateleadContactId")),
      BureauId: parseInt(document.getElementById("ddlBureauList").value == null ? "0" : document.getElementById("ddlBureauList").value == "" ? "0" : document.getElementById("ddlBureauList").value),
      FinancialYearId: parseInt(document.getElementById("ddlFinYYear").value == null ? "0" : document.getElementById("ddlFinYYear").value == "" ? "0" : document.getElementById("ddlFinYYear").value),
      ReportType: document.getElementById("ddlReportType").value == null ? "" : document.getElementById("ddlReportType").value,
      ReportUploadType: document.getElementById("ddlReportUplodType").value == null ? "" : document.getElementById("ddlReportUplodType").value,
      UploadMonth: document.getElementById("ddlUploadMonth").value == null ? CurrentUploadMonth : document.getElementById("ddlUploadMonth").value == "" ? CurrentUploadMonth : document.getElementById("ddlUploadMonth").value,
      UploadStep: parseInt(document.getElementById("ddlReportUplodStep").value == null ? "0" : document.getElementById("ddlReportUplodStep").value == "" ? "0" : document.getElementById("ddlReportUplodStep").value),
      UploadStatusId: parseInt(document.getElementById("ddlStatus").value == null ? "0" : document.getElementById("ddlStatus").value == "" ? "0" : document.getElementById("ddlStatus").value)
    }
    console.log(FiltrItem)
    await axios.post(global.APIURL + '/CorporateServiceUploadReportListDashboard', FiltrItem)
      .then(res => {
        setCorporateServiceUploadReportStepList(res.data);
      });
  };

  const getCorporateContactInfo = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");
    let VCorporateleadContactId = localStorage.getItem("CorporateleadContactId");
    let VLoginType = localStorage.getItem("LoginType");
    if (VLoginType == "MainLogin") {
      const CorporateData = {
        CorLeadId: VCorporateleadId,
        CorInformationLeadId: ""
      }
      axios.post(global.APIURL + '/CorporateContactInfo', CorporateData)
        .then(res => {
          setCorporateDetails(res.data);
        });
    }
    else if (VLoginType == "SubLogin") {
      const CorporateData = {
        CorLeadId: "",
        CorInformationLeadId: VCorporateleadContactId
      }
      axios.post(global.APIURL + '/CorporateContactInfo', CorporateData)
        .then(res => {
          setCorporateDetails(res.data);
        });
    }
    else {
      setCorporateDetails([]);
    }
  }

  const getCorporateServicesPackageList = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const CorporateData = {
      Id: VCorporateleadId,
    }
    axios.post(global.APIURL + '/CorporateServicesPackage', CorporateData)
      .then(res => {
        setCorporateServicesPackageList(res.data);
      });
  }

  const getCorporateServicesPackagepaymentList = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const CorporatepaymentData = {
      Id: VCorporateleadId,
    }
    axios.post(global.APIURL + '/CorporateLeadServiceppayment', CorporatepaymentData)
      .then(res => {
        setCorporateServicesPackagePaymentList(res.data);
      });
  }

  const getCorporateDetail = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const CorporateData = {
      Id: VCorporateleadId,
    }
    axios.post(global.APIURL + '/CorporateDetail', CorporateData)
      .then(res => {
        setCorporateDetail(res.data);
      });
  }

  const getClientConvertedList = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");
    let VCorporateleadContactId = localStorage.getItem("CorporateleadContactId");
    let VCorporateLead = localStorage.getItem("CorporateLead");

    if (VCorporateLead == "YES") {
      const CorporateData = {
        CorLeadId: "",
        CorInformationLeadId: VCorporateleadContactId
      }

      axios.post(global.APIURL + '/CClientDashboardconvetedList', CorporateData)
        .then(res => {
          setConvertedContact(res.data);
        });
    }
    else if (VCorporateLead == "") {

      const CorporateData = {
        CorLeadId: VCorporateleadId,
        CorInformationLeadId: ""
      }

      axios.post(global.APIURL + '/CClientDashboardconvetedList', CorporateData)
        .then(res => {
          setConvertedContact(res.data);
        });
    }
  }

  const getCorLeadMomList = () => {
    let VCorporateId = localStorage.getItem("CorporateleadId");
    let VCorporateContactId = localStorage.getItem("CorporateleadContactId");
    let VLoginType = localStorage.getItem("LoginType");
    const CorporateData = {
      CorporateId: VCorporateId == null ? '0' : VCorporateId == '' ? '0' : VCorporateId,
      CorporateContactId: VCorporateContactId == null ? '0' : VCorporateContactId == '' ? '0' : VCorporateContactId,
      CorporateLoginType: VLoginType == null ? '' : VLoginType,
      SpMode: 'DashboardSeleteAll'
    }

    axios.post(global.APIURL + '/WebCorporateLeadMomList', CorporateData)
      .then(res => {
        var VCorporateMeetingList = [];
        for (let cm = 0; cm < res.data.length; cm++) {
          let VMeetingheadnote = res.data[cm].Meetingheadnote;
          let Meetingsummary = res.data[cm].Meetingsummary;
          let MeetingDay = res.data[cm].MDDay;
          let MeetingMonth = res.data[cm].MDMonth;
          let MeetingYear = res.data[cm].MDYear;
          let Meetingtime = res.data[cm].Meetingtime;
          let Nextmeetingdate = res.data[cm].Nextmeetingdate;
          var Agenda = res.data[cm].Agenda;
          let Point = res.data[cm].Point;
          let Name = res.data[cm].Name;
          let Address = res.data[cm].Address;
          let MeetingWith = res.data[cm].MeetingWith;


          let AgendaArr = Agenda == null ? [] : Agenda == null ? [] : Agenda.split('+');
          var VAgendaList = [];
          for (let ag = 0; ag < AgendaArr.length; ag++) {
            const AgendaTitle = AgendaArr[ag];
            const ATItem = {
              Agenda: AgendaTitle,
            };
            VAgendaList.push(ATItem);
          }


          let PointArr = Point == null ? [] : Point == null ? [] : Point.split('+');
          var VPointList = [];
          for (let ag = 0; ag < PointArr.length; ag++) {
            const PointTitle = PointArr[ag];
            const PTItem = {
              Point: PointTitle,
            };
            VPointList.push(PTItem);
          }


          const CorporateMeetingItem = {
            Meetingheadnote: VMeetingheadnote,
            Meetingsummary: Meetingsummary,
            MeetingDay: MeetingDay,
            MeetingMonth: MeetingMonth,
            MeetingYear: MeetingYear,
            Meetingtime: Meetingtime,
            Nextmeetingdate: Nextmeetingdate,
            Address: Address,
            AgendaList: VAgendaList,
            PointList: VPointList,
            MeetingWith: MeetingWith,
            Name: Name,
            ModelName1: 'exampleModal' + cm.toString(),
            ModelName2: '#exampleModal' + cm.toString()
          };
          VCorporateMeetingList.push(CorporateMeetingItem);
        }
        setCorporateMeetingList(VCorporateMeetingList);
      });
  }

  const ServicesUploadReportTypeStepFileList = async (item1) => {

    const FiltrItem = {
      Id: parseInt(item1 == null ? "0" : item1 == "" ? "0" : item1),
    }
    await axios.post(global.APIURL + '/CorporateServiceUploadReportStepFileList', FiltrItem)
      .then(res => {
        setCorporateServiceUploadReportStepFileList(res.data);
        $('#MPServicesUploadReportStepFileList').modal('show');
      });
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {TotalReportUpload}
                      </h2>
                      <p>
                        Total Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {ConsumerReportUpload}
                      </h2>
                      <p>
                        Consumer Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {CommercialReportUpload}
                      </h2>
                      <p>
                        Commercial Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {MicroFinanceReportUpload}
                      </h2>
                      <p>
                        Micro-Finance Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {TodayTotalReportUpload}
                      </h2>
                      <p>
                        Today Total<br /> Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {TodayConsumerReportUpload}
                      </h2>
                      <p>
                        Today Consumer<br /> Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {TodayCommercialReportUpload}
                      </h2>
                      <p>
                        Today Commercial <br />Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {TodayMicroFinanceReportUpload}
                      </h2>
                      <p>
                        Today Micro-Finance<br />Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {MonthlyTotalReportUpload}
                      </h2>
                      <p>
                        {CurrentUploadMonth} Total<br /> Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {MonthlyConsumerReportUpload}
                      </h2>
                      <p>
                        {CurrentUploadMonth} Consumer<br /> Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {MonthlyCommercialReportUpload}
                      </h2>
                      <p>
                        {CurrentUploadMonth} Commercial <br />Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="contentmaindashboard">
                      <h2>
                        {MonthlyMicroFinanceReportUpload}
                      </h2>
                      <p>
                        {CurrentUploadMonth} Micro-Finance<br />Report Upload
                      </p>
                    </div>
                    <div className="imgboxdashboardmain">
                      <img src={totalleads}></img>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </Row>
          <div className="row d-none">
            <div className="col-md-6">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Corporate Information</p>
                  {CorporateDetail?.map((item, idx) => (
                    <div className="ciboxmain" key={idx}>
                      <div className="mainciboxgride">
                        <div >
                          <p className="ciboxtitlegrid">Network Type</p>
                          <p className="mainciboxgriddescription">{item.ChannelType}</p>

                        </div>

                        <div >
                          <p className="ciboxtitlegrid">Corporate</p>
                          <p className="mainciboxgriddescription">{item.ChannelMaster}</p>

                        </div>



                        <div >
                          <p className="ciboxtitlegrid">Email Address</p>
                          <p className="mainciboxgriddescription">{item.EmailAddress}</p>

                        </div>


                      </div>
                      <div className="mainciboxgride1">
                        <div className="text-center">
                          <p className="ciboxtitlegrid">Branch Code</p>
                          <p className="mainciboxgriddescription">{item.Branchcode}</p>
                        </div>
                        <div className="text-center">
                          <p className="ciboxtitlegrid">Corporate Status</p>
                          <p className="mainciboxgriddescription">{item.CorporateStatus}</p>
                        </div>
                        <div className="text-center">
                          <p className="ciboxtitlegrid">Registered Date</p>
                          <p className="mainciboxgriddescription">{item.RegisterDate}</p>
                        </div>
                        <div className="text-center">
                          <p className="ciboxtitlegrid">Converted Date</p>
                          <p className="mainciboxgriddescription">{item.ConvertDate}</p>
                        </div>
                        <div className="text-center">
                          <p className="ciboxtitlegrid">Closed Date</p>
                          <p className="mainciboxgriddescription">{item.CloseDate}</p>
                        </div>
                      </div>
                      <div className="mainaddressboxci">
                        <div className="w-100">
                          <p className="ciboxtitlegrid">Address</p>
                          <p className="mainciboxgriddescription">{item.Address}</p>
                        </div>
                      </div>

                    </div>
                  ))}
                </div>

              </div>
            </div>
            <div className="col-md-6">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Corporate Contact</p>
                  {LoginType == 'MainLogin' ?
                    <div className="row mt-4">
                      {CorporateDetails.map((item, idx) => (
                        <div className="col-md-6" key={idx}>
                          <div className="ccboxmaindashb">
                            <div className="d-flex">
                              <div className="ccboxmaindashbimgdiv">
                                <img src={maincontactimage}></img>

                              </div>
                              <div>

                                <p className="ciboxtitlegrid mb-1">{item.Name}</p>
                                <p className="mainciboxgriddescription">{item.Designation}</p>

                              </div>

                            </div>
                            <div className="ccboxlinetext"></div>
                            <div className="d-flex ccdetailscall mb-1">
                              <div>
                                <i className="las la-phone-volume "></i>
                              </div>
                              <div>
                                <p className="mb-0">{item.Mobilenumber}</p>
                              </div>
                            </div>
                            <div className="d-flex ccmaildetails">
                              <div>
                                <i className="las la-envelope  "></i>
                              </div>
                              <div>
                                <p className="mb-0">{item.Email}</p>
                              </div>
                            </div>

                          </div>

                        </div>
                      ))}

                    </div> : ''}


                </div>

              </div>
            </div>
          </div>
          <div className="row d-none">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Registration Services Package</p>

                  <div className='maindasboardtableeditnew'>
                    <table className="table mt-0 mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" className='text-center'>#</th>
                          <th scope="col">Services Package</th>
                          <th scope="col" className='text-center'>Type</th>
                          <th scope="col" className='text-center'>Price</th>
                          <th scope="col" className='text-center'>Total Amount</th>
                          <th scope="col" className='text-center'>Recived Amount</th>
                          <th scope="col" className='text-center'>Due Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {CorporateServicesPackageList?.map((item, idx) => (
                          <tr key={idx}>
                            <td className='text-center'>{idx + 1}</td>
                            {/* <td><a className='shyperlink' href="/ManageInformation" >{item.ServiceTitle}</a></td> */}
                            <td>{item.ServiceTitle}</td>
                            <td className='text-center'>{item.ServicesPackageType}</td>
                            <td className='text-center'>{item.PlanAmount}</td>
                            <td className='text-center'>{item.TotalAmount}</td>
                            <td className='text-center'>{item.TotalRecivedAmount}</td>
                            <td className='text-center'>{item.TotalDueAmount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                </div>

              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <div className='d-flex justify-content-between mb-3 align-items-center'>
                    <p className="maincommontitlefordahboard mb-0">Services File Upload Log</p>
                    <ul className="back_color_info editback_color_info mb-0">
                      <li><span className="consumer-row-color"></span> Consumer</li>
                      <li><span className="commercial-row-color"></span> Commercial</li>
                      <li><span className="microfinance-row-color"></span> Micro-Finance</li>
                    </ul>
                    <select className="form-select" Id="ddlBureauList" name="ddlBureauList" onChange={e => getCorporateServiceUploadReportStepList()}>
                      <option value={0} >All Bureau</option>
                      {BureauList?.map((item, idx) => (
                        <option value={item.BId} key={idx}>{item.Title}</option>
                      ))}
                    </select>
                  </div>
                  <hr />
                  <div className="row mt-3">
                    <div className="col-md-2">
                      <select className="form-select" Id="ddlFinYYear" name="ddlFinYYear" onChange={e => getCorporateServiceUploadReportStepList()}>
                        <option value={0} >ALL</option>
                        {FinancialYearList?.map((item, idx) => (
                          <option value={item.Id} key={idx}>{item.FinancialYear}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <select className="form-select" Id="ddlReportType" name="ddlReportType" onChange={e => getCorporateServiceUploadReportStepList()}>
                        <option value={''} >ALL</option>
                        <option value={'Consumer'} >Consumer</option>
                        <option value={'Commercial'} >Commercial</option>
                        <option value={'Micro-Finance'} >Micro-Finance</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <select className="form-select" Id="ddlReportUplodType" name="ddlReportUplodType" onChange={e => getCorporateServiceUploadReportStepList()}>
                        <option value={''} >ALL</option>
                        <option value={'Credit Info Upload'} >Credit Info Upload</option>
                        <option value={'OLM Upload'} >OLM Upload</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <select className="form-select" Id="ddlUploadMonth" name="ddlUploadMonth" onChange={e => getCorporateServiceUploadReportStepList()}>
                        <option value={''} >ALL</option>
                        {UploadMonthList?.map((item1, idx1) => (
                          <option value={item1.UploadMonth} key={idx1}>{item1.UploadMonth}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <select className="form-select" Id="ddlReportUplodStep" name="ddlReportUplodStep" onChange={e => getCorporateServiceUploadReportStepList()}>
                        <option value={0} >ALL</option>
                        <option value={1} >Step-1 Uploading</option>
                        <option value={2} >Step-2 Checking</option>
                        <option value={3} >Step-3 Processing</option>
                        <option value={4} >Step-4 Confirmation</option>
                        <option value={5} >Step-5 Reject/Success</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <select className="form-select" Id="ddlStatus" name="ddlStatus" onChange={e => getCorporateServiceUploadReportStepList()}>
                        <option value={0}>ALL</option>
                        {ServiceStatusList.map((item) => (
                          <option key={item.Id} value={item.Id}>
                            {item.StatusTitle}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='maindasboardtableeditnew verticalaligntable'>
                    <table className="table mt-0 mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" className='text-center'>#</th>
                          <th scope="col">File Name</th>
                          <th scope="col">Bureau</th>
                          <th scope="col">Type</th>
                          <th scope="col">Nature</th>
                          <th scope="col">Upload Month</th>
                          <th scope="col">Step</th>
                          <th scope="col">Date Uploaded</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {CorporateServiceUploadReportStepList?.map((item, idx) => (
                          <tr key={idx} className={item.ReportType == 'Consumer' ? 'consumer-row-color' : item.ReportType == 'Commercial' ? 'commercial-row-color' : item.ReportType == 'Micro-Finance' ? 'microfinance-row-color' : ''}>
                            <td className='text-center'>{idx + 1}</td>
                            <td className="maintititletablewidth"><a onClick={e => ServicesUploadReportTypeStepFileList(item.Id)}>{item.ReportTitle}</a></td>
                            <td>{item.BureauTitle}</td>
                            <td>{item.ReportType}</td>
                            <td>{item.ReportUploadType}</td>
                            <td>{item.UploadMonth}</td>
                            <td><span className="badge badge-soft-primary font-12">{item.StepTitle}</span></td>
                            <td>{item.SUploadDate}</td>
                            <td><span className="badge badge-soft-primary font-12">{item.StatusTitle}</span></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="modal fade bd-example-modal-sm" id="MPServicesUploadReportStepFileList" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div>
                              <h5 className="modal-title" id="exampleModalLabel">Upload File List</h5>
                            </div>
                            <div>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                          </div>
                          <div className="modal-body">
                            <Row>
                              <Col lg={12}>
                                <div className='maindasboardtableeditnew'>
                                  <table className="table">
                                    <thead className="table-light">
                                      <tr>
                                        <th scope="col" className='text-center'>#</th>
                                        <th scope="col">Upload File</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {CorporateServiceUploadReportStepFileList.map((item, index) => (
                                        <tr key={index}>
                                          <td className='text-center'>{index + 1}</td>
                                          <td><a href={item.UploadFilePath} target="_blank" rel="noopener noreferrer">{item.UploadFileName}</a></td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </Col>

                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
          <div className="row d-none" >
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Registration Services Package Due payment</p>

                  <div className='maindasboardtableeditnew'>
                    <table className="table mt-0 mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" className='text-center'>#</th>
                          <th scope="col">Services Package</th>
                          <th scope="col" className='text-center'>Type</th>
                          <th scope="col" className='text-center'>Due Date</th>
                          <th scope="col" className='text-center'>Amount</th>
                          <th scope="col" className='text-center'>Gst Amount</th>
                          <th scope="col" className='text-center'>Total Amount</th>
                          <th scope="col" className='text-center'>Payment Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {CorporateServicesPackagePaymentList?.map((item, idx) => (
                          <tr key={idx}>
                            <td className='text-center'>{idx + 1}</td>
                            <td>{item.ServiceTitle}</td>
                            <td className='text-center'>{item.ServicesPackageType}</td>
                            <td className='text-center'>{item.DueDate}</td>
                            <td className='text-center'>{item.Amount}</td>
                            <td className='text-center'>{item.GstAmount}</td>
                            <td className='text-center'>{item.TotalAmount}</td>
                            <td className='text-center'>{item.PaymentStatus}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                </div>

              </div>
            </div>

          </div>
          <Row className='d-none'>
            <Col xl='12'>

              {/* <Row>
                <Col md="12" className="mb-2">
                  <Card className="mini-stats-wid ">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2">Registration Services Package</CardTitle>
                        </Col>
                        <hr />
                      </Row>
                      <Col className="col-lg-12 mb-2 mt-2">
                         <div className='maindasboardtableeditnew'>
                          <table className="table mt-0 mb-0">
                      <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col">Services Package</th>
                                <th scope="col" className='text-center'>Type</th>
                                <th scope="col" className='text-center'>Price</th>
                                <th scope="col" className='text-center'>Total Amount</th>
                                <th scope="col" className='text-center'>Recived Amount</th>
                                <th scope="col" className='text-center'>Due Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {CorporateServicesPackageList?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td>{item.ServiceTitle}</td>
                                  <td className='text-center'>{item.ServicesPackageType}</td>
                                  <td className='text-center'>{item.PlanAmount}</td>
                                  <td className='text-center'>{item.TotalAmount}</td>
                                  <td className='text-center'>{item.TotalRecivedAmount}</td>
                                  <td className='text-center'>{item.TotalDueAmount}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
              {/* {LoginType == 'MainLogin' ?
                <Row>
                  <Col md="12" className="mb-2">

                    <Card>
                      <CardBody className="pt-2 pb-2 ps-3 pe-3">
                        <Row className="justify-content-between ">
                          <Col className="col-lg-4 mb-0">
                            <CardTitle className="mt-2"> Corporate Contact </CardTitle>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>


                    <Row>
                      {CorporateDetails.map((item, idx) => (
                        <Col className="col-md-3" key={idx}>
                          <Card className="mb-3">
                            <CardBody>
                              <div>
                                <div className="d-flex ">
                                  <div className="">
                                    <div className="maindatemeetingboxnew2">
                                      <img className='dashboard-profile-user' src="/static/media/avatar-1.c69c03fa.jpg"></img>
                                    </div>




                                  </div>
                                  <div >


                                    <p className="meetingheadingnotsmain"> {item.Name} </p>
                                    <div >

                                      <div className="d-flex">
                                        <div>
                                          <i className="las la-phone-volume clockiconedit"></i>
                                        </div>
                                        <div>
                                          <p className="meetingtimebox">{item.Mobilenumber}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div >

                                      <div className="d-flex">
                                        <div>
                                          <i className="las la-envelope clockiconedit"></i>
                                        </div>
                                        <div>
                                          <p className="meetingtimebox">{item.Email}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div >

                                      <div className="d-flex">

                                        <div>
                                          <i className="las la-briefcase locationiconnew"></i>
                                        </div>
                                        <div>
                                          <p className="locationmaindatatext">{item.Designation}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>


                  </Col>
                </Row> : ''} */}
              {LoginType == 'SubLogin' ?
                <Row>
                  <Col md="12" className="mb-2">
                    <Card className="mini-stats-wid ">
                      <CardBody>
                        <Row className="justify-content-between">
                          <Col className="col-lg-4 mb-0">
                            <CardTitle className="mt-2">Basic Information</CardTitle>
                          </Col>
                          <hr />
                        </Row>
                        <Row className="justify-content-between">
                          <hr />
                        </Row>
                        <Col className="col-lg-12 mb-2">
                          {CorporateDetails?.map((item, idx) => (
                            <Row className="mb-2" key={idx}>
                              <Col className="col-lg-3 col-md-4 col-12 mt-2" >
                                <label className="form-label">Name</label>
                                <Input type="text" className="form-control" readOnly defaultValue={item.Name} />
                              </Col>
                              <Col className="col-lg-3 col-md-4 col-12 mt-2">
                                <label className="form-label">Email Address</label>
                                <Input type="text" className="form-control" readOnly defaultValue={item.Email} />
                              </Col>
                              <Col className="col-lg-3 col-md-4 col-12 mt-2">
                                <label className="form-label">Contact Number</label>
                                <Input type="text" className="form-control" readOnly defaultValue={item.Mobilenumber} />
                              </Col>
                              <Col className="col-lg-3 col-md-12 col-12 mt-2">
                                <label className="form-label">Designation</label>
                                <Input type="text" className="form-control" readOnly defaultValue={item.Designation} />
                              </Col>
                            </Row>
                          ))}

                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> : ''}
              <Row>
                <Col md="12" className="mb-2">
                  <Card>
                    <CardBody className="pt-2 pb-2 ps-3 pe-3">

                      <Row className="justify-content-between ">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"> Meeting List </CardTitle>
                        </Col>

                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"><a href='/CorContactInfo/CorLeadMomList' className="viewlistbutton">View More</a></CardTitle>
                        </Col>

                      </Row>

                    </CardBody>
                  </Card>
                  <Row>
                    {CorporateMeetingList.map((item, idx) => (
                      <Col className="col-md-3" key={idx}>
                        <Card className="h-100">
                          <CardBody>
                            <div>
                              <div className="d-flex mb-2">
                                <div className="">
                                  <div className="maindatemeetingbox">
                                    <p>{item.MeetingDay}</p>
                                    <p>{item.MeetingMonth}</p>
                                    <p>{item.MeetingYear}</p>
                                  </div>
                                </div>
                                <div >
                                  <p className="meetingheadingnotsmain"> {item.Meetingheadnote} </p>
                                  <div >
                                    <div className="d-flex">
                                      <div>
                                        <i className="las la-clock clockiconedit"></i>
                                      </div>
                                      <div>
                                        <p className="meetingtimebox">{item.Meetingtime}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div >
                                    <div className="d-flex">
                                      <div>
                                        <i className="las la-map-marker locationiconnew"></i>
                                      </div>
                                      <div>
                                        <p className="locationmaindatatext">{item.Address}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {LoginType == 'MainLogin' ? <div>
                                <div className="d-flex align-items-center">
                                  <p className="boxmeetingtitle me-2">Attendee :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.Name}</p>
                                </div>
                                <hr className="mt-2 mb-2"></hr>
                              </div>
                                : ''}
                              <div>
                                <div className="d-flex align-items-center">
                                  <p className="boxmeetingtitle me-2">Meeting With :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.MeetingWith}</p>
                                </div>
                                <hr className="mt-2 mb-2"></hr>
                              </div>
                              <div>
                                <p className="boxmeetingtitle">Agenda</p>
                                <hr className="mt-2 mb-2"></hr>
                                <div>
                                  {item.AgendaList.map((Aitem, idx1) => (
                                    idx1 == 0 ?
                                      <div className="d-flex" key={idx1}>
                                        <div>
                                          <i className="fa-solid fa-circle pointsimageedit"></i>
                                        </div>
                                        <div>
                                          <p className="pointstextedit">{Aitem.Agenda}</p>
                                        </div>
                                      </div> :
                                      idx1 == 1 ?
                                        <div className="d-flex" key={idx1}>
                                          <div>
                                            <i className="fa-solid fa-circle pointsimageedit"></i>
                                          </div>
                                          <div>
                                            <p className="pointstextedit">{Aitem.Agenda}</p>
                                          </div>
                                        </div> : ''
                                  ))}
                                </div>
                              </div>
                              <div>
                                <p className="boxmeetingtitle">Point</p>
                                <hr className="mt-2 mb-2"></hr>
                                <div>
                                  {item.PointList.map((Pitem, idx2) => (
                                    idx2 == 0 ?
                                      <div className="d-flex" key={idx2}>
                                        <div>
                                          <i className="fa-solid fa-circle pointsimageedit"></i>
                                        </div>
                                        <div>
                                          <p className="pointstextedit">{Pitem.Point}</p>
                                        </div>
                                      </div>
                                      : idx2 == 1 ?
                                        <div className="d-flex" key={idx2}>
                                          <div>
                                            <i className="fa-solid fa-circle pointsimageedit"></i>
                                          </div>
                                          <div>
                                            <p className="pointstextedit">{Pitem.Point}</p>
                                          </div>
                                        </div>
                                        : ''
                                  ))}
                                </div>
                              </div>
                              <div>
                                <p className="boxmeetingtitle">Meeting summary</p>
                                <hr className="mt-2 mb-2"></hr>
                                <div>
                                  <div className="d-flex">
                                    <p className="pointstextedit">{item.Meetingsummary}</p>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <button type="button" className="btn btn-sm btn-success mt-2 w-100" data-bs-toggle="modal" data-bs-target={item.ModelName2}><i className="las la-eye mr-2 eyeiconnew"></i> View</button>
                                <div className="modal fade" id={item.ModelName1} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Meeting Details</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                      </div>
                                      <div className="modal-body mainmodalmeetingdestails">
                                        <div>
                                          <div className="d-flex mb-2">
                                            <div className="">
                                              <div className="maindatemeetingbox">
                                                <p>{item.MeetingDay}</p>
                                                <p>{item.MeetingMonth}</p>
                                                <p>{item.MeetingYear}</p>
                                              </div>
                                            </div>
                                            <div >
                                              <p className="meetingheadingnotsmain"> {item.Meetingheadnote} </p>
                                              <div >
                                                <div className="d-flex">
                                                  <div>
                                                    <i className="las la-clock clockiconedit"></i>
                                                  </div>
                                                  <div>
                                                    <p className="meetingtimebox">{item.Meetingtime}</p>
                                                  </div>
                                                </div>
                                              </div>

                                              <div >

                                                <div className="d-flex">
                                                  <div>
                                                    <i className="las la-map-marker locationiconnew"></i>
                                                  </div>
                                                  <div>
                                                    <p className="locationmaindatatext">{item.Address}</p>
                                                  </div>
                                                </div>
                                              </div>



                                            </div>

                                          </div>
                                          {LoginType == 'MainLogin' ? <div>
                                            <div className="d-flex align-items-center">
                                              <p className="boxmeetingtitle me-2">Attendee :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.Name}</p>
                                            </div>
                                            <hr className="mt-2 mb-2"></hr>
                                          </div>
                                            : ''}
                                          <div>
                                            <div className="d-flex align-items-center">
                                              <p className="boxmeetingtitle me-2">Meeting With :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.MeetingWith}</p>
                                            </div>
                                            <hr className="mt-2 mb-2"></hr>
                                          </div>

                                          <div>
                                            <p className="boxmeetingtitle">Agenda</p>
                                            <hr className="mt-2 mb-2"></hr>
                                            <div>
                                              <div>
                                                {item.AgendaList.map((Aitem, idx1) => (
                                                  <div className="d-flex" key={idx1}>
                                                    <div>
                                                      <i className="fa-solid fa-circle pointsimageedit"></i>
                                                    </div>
                                                    <div>
                                                      <p className="pointstextedit">{Aitem.Agenda}</p>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                          <div>
                                            <p className="boxmeetingtitle">Point</p>
                                            <hr className="mt-2 mb-2"></hr>
                                            <div>
                                              {item.PointList.map((Pitem, idx2) => (
                                                <div className="d-flex" key={idx2}>
                                                  <div>
                                                    <i className="fa-solid fa-circle pointsimageedit"></i>
                                                  </div>
                                                  <div>
                                                    <p className="pointstextedit">{Pitem.Point}</p>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                          <div>
                                            <p className="boxmeetingtitle">Meeting summary</p>
                                            <hr className="mt-2 mb-2"></hr>
                                            <div>
                                              <div className="d-flex">
                                                <p className="pointstextedit">{item.Meetingsummary}</p>
                                              </div>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );

}

export default Dashboard;
