import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import Commheader from "../../../components/HorizontalLayout/Header";
import LinkApp from 'App';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const CorLeadMomList = () => {

  const [CorporateMeetingList, setCorporateMeetingList] = useState([])
  const [CorporateMeetingFilterList, setCorporateMeetingFilterList] = useState([])
  const [CreditStatusFlag, setCreditStatusFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");
  const [CorLeadId, setCorLeadId] = useState([]);
  const [CorporateContactId, setCorporateContactId] = useState([]);
  const [CorporateDetails, setCorporateDetails] = useState([]);
  const [CorporateMeetingAgenda, setCorporateMeetingAgenda] = useState([]);
  const [CorporateMeetingPointList, setCorporateMeetingPointList] = useState([]);

  const [LoginType, setLoginType] = useState('');

  let history = useHistory();

  useEffect(() => {
    let VLoginType = localStorage.getItem("LoginType");
    setLoginType(VLoginType);
    getCorLeadMomList();

  }, []);

  const getCorLeadMomList = () => {
    let VCorporateId = localStorage.getItem("CorporateleadId");
    let VCorporateContactId = localStorage.getItem("CorporateleadContactId");
    let VLoginType = localStorage.getItem("LoginType");
    const CorporateData = {
      CorporateId: VCorporateId == null ? '0' : VCorporateId == '' ? '0' : VCorporateId,
      CorporateContactId: VCorporateContactId == null ? '0' : VCorporateContactId == '' ? '0' : VCorporateContactId == "" ? '0' : VCorporateContactId,
      CorporateLoginType: VLoginType == null ? '' : VLoginType,
      SpMode: 'SeleteAll'
    }

    axios.post(global.APIURL + '/WebCorporateLeadMomList', CorporateData)
      .then(res => {
        var VCorporateMeetingList = [];
        var VCorporateMeetingAgenda = [];
        for (let cm = 0; cm < res.data.length; cm++) {
          let VMeetingheadnote = res.data[cm].Meetingheadnote;
          let Meetingsummary = res.data[cm].Meetingsummary;
          let MeetingDay = res.data[cm].MDDay;
          let MeetingMonth = res.data[cm].MDMonth;
          let MeetingYear = res.data[cm].MDYear;
          let Meetingtime = res.data[cm].Meetingtime;
          let Nextmeetingdate = res.data[cm].Nextmeetingdate;
          var Agenda = res.data[cm].Agenda;
          let Point = res.data[cm].Point;
          let Address = res.data[cm].Address;
          let MeetingWith = res.data[cm].MeetingWith;
          let Name = res.data[cm].Name;
          let AgendaArr = Agenda == null ? [] : Agenda == null ? [] : Agenda.split('+');
          var VAgendaList = [];
          for (let ag = 0; ag < AgendaArr.length; ag++) {
            const AgendaTitle = AgendaArr[ag];
            const ATItem = {
              Agenda: AgendaTitle,
            };
            VAgendaList.push(ATItem);
          }


          let PointArr = Point == null ? [] : Point == null ? [] : Point.split('+');
          var VPointList = [];
          for (let ag = 0; ag < PointArr.length; ag++) {
            const PointTitle = PointArr[ag];
            const PTItem = {
              Point: PointTitle,
            };
            VPointList.push(PTItem);
          }

          const CorporateMeetingItem = {
            Meetingheadnote: VMeetingheadnote,
            Meetingsummary: Meetingsummary,
            MeetingDay: MeetingDay,
            MeetingMonth: MeetingMonth,
            MeetingYear: MeetingYear,
            Meetingtime: Meetingtime,
            Nextmeetingdate: Nextmeetingdate,
            Address: Address,
            AgendaList: VAgendaList,
            PointList: VPointList,
            MeetingWith: MeetingWith,
            Name: Name,
            ModelName1: 'exampleModal' + cm.toString(),
            ModelName2: '#exampleModal' + cm.toString()
          };

          VCorporateMeetingList.push(CorporateMeetingItem);

        }
        setCorporateMeetingList(VCorporateMeetingList);
        setCorporateMeetingAgenda(VAgendaList);
        setCorporateMeetingPointList(VPointList);

      });
  }


  const ViewMeetingDetails = async (e, MeetingId) => {
    var CorporateMeetingListFilter = CorporateMeetingList.filter(x => x.MId == MeetingId);
    setCorporateMeetingFilterList(CorporateMeetingListFilter);
  }

  // const exportPDF = () => {

  //   const title = "Meeting Information List";

  //   const  header = [[{ content: 'Meeting Information', colSpan: 10, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }],["#","Name","Address","MeetingWith","Address","Meetingheadnote","Meetingsummary","Nextmeetingdate","AgendaList"]];
  //   const data = CorporateMeetingList.map((item, index) => [index + 1, item.Name,item.Address,item.MeetingWith,item.Address,item.Meetingheadnote,item.Meetingsummary,item.Nextmeetingdate]);
  //   let content = {
  //     startY:50,
  //     head: header,
  //     body: data,
  //     headStyles: { fillColor: ['#2ca67a'] },
  //   };

  //   const  header1 = [[{ content: 'Agenda', colSpan: 7, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }],["#","Agenda"]];
  //   const data1 = CorporateMeetingAgenda.map((item, index) => [index + 1, item.Agenda]);
  //   let content1 = {

  //     head: header1,
  //     body: data1,
  //     headStyles: { fillColor: ['#2ca67a'] },

  //   };


  //   var doc = new jsPDF('p', 'pt');
  //   doc.text(title,40, 40);
  //   doc.autoTable(content);
  //   doc.autoTable(content1);
  //    doc.save("ManageMeeting.pdf")

  // }

  return (
    <React.Fragment>
      <Commheader />
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Meeting Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body mainmodalmeetingdestails">

              {CorporateMeetingFilterList.map((item, idx) => (
                <div key={idx}>
                  <div className="d-flex mb-2">
                    <div className="">
                      <div className="maindatemeetingbox">
                        <p>{item.MeetingDay}</p>
                        <p>{item.MeetingMonth}</p>
                        <p>{item.MeetingYear}</p>
                      </div>

                    </div>
                    <div >
                      <p className="meetingheadingnotsmain"> {item.Meetingheadnote} </p>
                      <div >

                        <div className="d-flex">
                          <div>
                            <i className="las la-clock clockiconedit"></i>
                          </div>
                          <div>
                            <p className="meetingtimebox">{item.Meetingtime}</p>
                          </div>
                        </div>
                      </div>

                      <div >

                        <div className="d-flex">
                          <div>
                            <i className="las la-map-marker locationiconnew"></i>
                          </div>
                          <div>
                            <p className="locationmaindatatext">{item.Address}</p>
                          </div>
                        </div>
                      </div>



                    </div>

                  </div>
                  {LoginType == 'MainLogin' ? <div>
                    <div className="d-flex align-items-center">
                      <p className="boxmeetingtitle me-2">Attendee :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.Name}</p>
                    </div>
                    <hr className="mt-2 mb-2"></hr>
                  </div>
                    : ''}
                  <div>
                    <div className="d-flex align-items-center">
                      <p className="boxmeetingtitle me-2">Meeting With :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.MeetingWith}</p>
                    </div>
                    <hr className="mt-2 mb-2"></hr>
                  </div>
                  <div>
                    <p className="boxmeetingtitle">Agenda</p>
                    <hr className="mt-2 mb-2"></hr>
                    <div>
                      <div>
                        {item.AgendaList.map((Aitem, idx1) => (
                          <div className="d-flex" key={idx1}>
                            <div>
                              <i className="fa-solid fa-circle pointsimageedit"></i>
                            </div>
                            <div>
                              <p className="pointstextedit">{Aitem.Agenda}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="boxmeetingtitle">Point</p>
                    <hr className="mt-2 mb-2"></hr>
                    <div>
                      {item.PointList.map((Pitem, idx2) => (
                        <div className="d-flex" key={idx2}>
                          <div>
                            <i className="fa-solid fa-circle pointsimageedit"></i>
                          </div>
                          <div>
                            <p className="pointstextedit">{Pitem.Point}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p className="boxmeetingtitle">Meeting summary</p>
                    <hr className="mt-2 mb-2"></hr>
                    <div>
                      <div className="d-flex">
                        <p className="pointstextedit">{item.Meetingsummary}</p>
                      </div>
                    </div>
                  </div>


                </div>
              ))}


            </div>

          </div>
        </div>
      </div>
      <div className="page-content">
        <MetaTags>
          <title>Corporate Contact | KC Corporate Admin</title>
        </MetaTags>
        <div className="container-fluid">

          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Manage Of Meeting</CardTitle>
              </div>
            </Col>
            {/* <Col className="col-lg-4 co-md-6 col-6">
              <ReactHtmlTableToExcel
              className="btn btn-primary btn-sm float-end font-14 me-2 btncolor"
              table="emp"
              filename="MomList"
              sheet="Sheet"
              buttonText="Export Excel" />
            
              { <button className="btn btn-primary btn-sm float-end font-14 me-2 btncolor" onClick={exportPDF}>Export PDF</button>}
                
            </Col> */}
          </Row>
          <form >
            <Row>
              {CorporateMeetingList.map((item, idx) => (
                <Col className="col-md-3 mb-3" key={idx}>
                  <Card className="h-100">
                    <CardBody>
                      <div>
                        <div className="d-flex mb-2">
                          <div className="">
                            <div className="maindatemeetingbox">
                              <p>{item.MeetingDay}</p>
                              <p>{item.MeetingMonth}</p>
                              <p>{item.MeetingYear}</p>
                            </div>




                          </div>
                          <div >
                            <p className="meetingheadingnotsmain"> {item.Meetingheadnote} </p>
                            <div >

                              <div className="d-flex">
                                <div>
                                  <i className="las la-clock clockiconedit"></i>
                                </div>
                                <div>
                                  <p className="meetingtimebox">{item.Meetingtime}</p>
                                </div>
                              </div>
                            </div>

                            <div >

                              <div className="d-flex">
                                <div>
                                  <i className="las la-map-marker locationiconnew"></i>
                                </div>
                                <div>
                                  <p className="locationmaindatatext">{item.Address}</p>
                                </div>
                              </div>
                            </div>



                          </div>

                        </div>
                        {LoginType == 'MainLogin' ? <div>
                          <div className="d-flex align-items-center">
                            <p className="boxmeetingtitle me-2">Attendee :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.Name}</p>
                          </div>
                          <hr className="mt-2 mb-2"></hr>
                        </div>
                          : ''}
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="boxmeetingtitle me-2">Meeting With :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.MeetingWith}</p>
                          </div>
                          <hr className="mt-2 mb-2"></hr>
                        </div>

                        <div>
                          <p className="boxmeetingtitle">Agenda</p>
                          <hr className="mt-2 mb-2"></hr>
                          <div>
                            {item.AgendaList.map((Aitem, idx1) => (
                              idx1 == 0 ?
                                <div className="d-flex" key={idx1}>
                                  <div>
                                    <i className="fa-solid fa-circle pointsimageedit"></i>
                                  </div>
                                  <div>
                                    <p className="pointstextedit">{Aitem.Agenda}</p>
                                  </div>
                                </div> :
                                idx1 == 1 ?
                                  <div className="d-flex" key={idx1}>
                                    <div>
                                      <i className="fa-solid fa-circle pointsimageedit"></i>
                                    </div>
                                    <div>
                                      <p className="pointstextedit">{Aitem.Agenda}</p>
                                    </div>
                                  </div> : ''
                            ))}
                          </div>
                        </div>
                        <div>
                          <p className="boxmeetingtitle">Point</p>
                          <hr className="mt-2 mb-2"></hr>
                          <div>
                            {item.PointList.map((Pitem, idx2) => (
                              idx2 == 0 ?
                                <div className="d-flex" key={idx2}>
                                  <div>
                                    <i className="fa-solid fa-circle pointsimageedit"></i>
                                  </div>
                                  <div>
                                    <p className="pointstextedit">{Pitem.Point}</p>
                                  </div>
                                </div>
                                : idx2 == 1 ?
                                  <div className="d-flex" key={idx2}>
                                    <div>
                                      <i className="fa-solid fa-circle pointsimageedit"></i>
                                    </div>
                                    <div>
                                      <p className="pointstextedit">{Pitem.Point}</p>
                                    </div>
                                  </div>
                                  : ''
                            ))}
                          </div>
                        </div>
                        <div>
                          <p className="boxmeetingtitle">Meeting summary</p>
                          <hr className="mt-2 mb-2"></hr>
                          <div>
                            <div className="d-flex">
                              <p className="pointstextedit">{item.Meetingsummary}</p>
                            </div>
                          </div>
                        </div>

                        <div>
                          <button type="button" className="btn btn-sm btn-success mt-2 w-100" data-bs-toggle="modal" data-bs-target={item.ModelName2}><i className="las la-eye mr-2 eyeiconnew"></i> View</button>
                          <div className="modal fade" id={item.ModelName1} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title" id="exampleModalLabel">Meeting Details</h5>
                                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body mainmodalmeetingdestails">
                                  <div>
                                    <div className="d-flex mb-2">
                                      <div className="">
                                        <div className="maindatemeetingbox">
                                          <p>{item.MeetingDay}</p>
                                          <p>{item.MeetingMonth}</p>
                                          <p>{item.MeetingYear}</p>
                                        </div>
                                      </div>
                                      <div >
                                        <p className="meetingheadingnotsmain"> {item.Meetingheadnote} </p>
                                        <div >
                                          <div className="d-flex">
                                            <div>
                                              <i className="las la-clock clockiconedit"></i>
                                            </div>
                                            <div>
                                              <p className="meetingtimebox">{item.Meetingtime}</p>
                                            </div>
                                          </div>
                                        </div>

                                        <div >

                                          <div className="d-flex">
                                            <div>
                                              <i className="las la-map-marker locationiconnew"></i>
                                            </div>
                                            <div>
                                              <p className="locationmaindatatext">{item.Address}</p>
                                            </div>
                                          </div>
                                        </div>



                                      </div>

                                    </div>
                                    {LoginType == 'MainLogin' ? <div>
                                      <div className="d-flex align-items-center">
                                        <p className="boxmeetingtitle me-2">Attendee :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.Name}</p>
                                      </div>
                                      <hr className="mt-2 mb-2"></hr>
                                    </div>
                                      : ''}
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <p className="boxmeetingtitle me-2">Meeting With :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.MeetingWith}</p>
                                      </div>
                                      <hr className="mt-2 mb-2"></hr>
                                    </div>

                                    <div>
                                      <p className="boxmeetingtitle">Agenda</p>
                                      <hr className="mt-2 mb-2"></hr>
                                      <div>
                                        <div>
                                          {item.AgendaList.map((Aitem, idx1) => (
                                            <div className="d-flex" key={idx1}>
                                              <div>
                                                <i className="fa-solid fa-circle pointsimageedit"></i>
                                              </div>
                                              <div>
                                                <p className="pointstextedit">{Aitem.Agenda}</p>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <p className="boxmeetingtitle">Point</p>
                                      <hr className="mt-2 mb-2"></hr>
                                      <div>
                                        {item.PointList.map((Pitem, idx2) => (
                                          <div className="d-flex" key={idx2}>
                                            <div>
                                              <i className="fa-solid fa-circle pointsimageedit"></i>
                                            </div>
                                            <div>
                                              <p className="pointstextedit">{Pitem.Point}</p>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    <div>
                                      <p className="boxmeetingtitle">Meeting summary</p>
                                      <hr className="mt-2 mb-2"></hr>
                                      <div>
                                        <div className="d-flex">
                                          <p className="pointstextedit">{item.Meetingsummary}</p>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </form>


        </div>
      </div>

    </React.Fragment>
  );
}

export default CorLeadMomList;