import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row, Input } from "reactstrap";
import Commheader from "../../../components/HorizontalLayout/Header";

import 'jspdf-autotable';


const Services = () => {
    const [FinancialYearList, setFinancialYearList] = useState([]);
    const [ServiceStatusList, setServiceStatusList] = useState([]);

    const [UploadMonthList, setUploadMonthList] = useState([]);
    const [UploadMonth, setUploadMonth] = useState('');
    const [UploadDate, setUploadDate] = useState('');
    const [UploadStatusId, setUploadStatusId] = useState(0);
    const [EditRowId, setEditRowId] = useState(0);
    const [UploadFileList, setUploadFileList] = useState([]);
    const MonthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const [CorporateServiceUploadReportList, setCorporateServiceUploadReportList] = useState([]);
    const [CorporateServiceUploadReportConsumerList, setCorporateServiceUploadReportConsumerList] = useState([]);
    const [CorporateServiceUploadReportCommercialList, setCorporateServiceUploadReportCommercialList] = useState([]);
    const [CorporateServiceUploadReportMicroFinanceList, setCorporateServiceUploadReportMicroFinanceList] = useState([]);
    const [CurrentUploadMonthTitle, setCurrentUploadMonthTitle] = useState('');
    const [CorporateServiceUploadReportStepList, setCorporateServiceUploadReportStepList] = useState([]);
    const [CorporateServiceUploadReportStepFileList, setCorporateServiceUploadReportStepFileList] = useState([]);

    useEffect(() => {
        getDashboardDropdownList();
        var today = new Date();
        var CurrentYear = today.getFullYear().toString();
        var VUploadMonthTitle = MonthNames[today.getMonth()] + '-' + CurrentYear;
        setCurrentUploadMonthTitle(VUploadMonthTitle);
        //getServiceStatusList();
        getCorporateServiceUploadReportList('');
        getCorporateServiceUploadReportConsumerList('');
        getCorporateServiceUploadReportCommercialList('');
        getCorporateServiceUploadReportMicroFinanceList('');
    }, []);


    const getDashboardDropdownList = async () => {

        const FiltrItem = {
            FinancialYearId: 0
        }
        await axios.post(global.APIURL + '/CorporateDashboardDropdownList', FiltrItem)
            .then(res => {
                setFinancialYearList(res.data.FinancialYearList);
                setUploadMonthList(res.data.UploadMonthList);
            });
    };

    function DocumentfetchPost(e) {
        for (let index = 0; index < e.target.files.length; index++) {
            var DocumentFile = e.target.files[index];
            var FolderName = "ServiceDocument";
            const formData = new FormData();
            formData.append('Document', DocumentFile);
            formData.append('FolderName', FolderName);
            var AUrl = global.APIURL + '/uploadavatar/';
            axios.post(AUrl, formData, { headers: { "Content-Type": DocumentFile.type }, })
                .then((res) => {
                    const DocumentFileDetail = {
                        FilePath: res.data.IName == null ? "" : res.data.IName,
                        FileName: res.data.FileName == null ? "" : res.data.FileName
                    };
                    UploadFileList.push(DocumentFileDetail);
                });
        }
    }

    const ddlReportFilterchange = async () => {
        getCorporateServiceUploadReportList('');
        getCorporateServiceUploadReportConsumerList('');
        getCorporateServiceUploadReportCommercialList('');
        getCorporateServiceUploadReportMicroFinanceList('');
    }

    const getCorporateServiceUploadReportList = async () => {
        const FiltrItem = {
            CorporateLeadId: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
            CorporateLeadUserId: parseInt(localStorage.getItem("CorporateleadContactId") == null ? "0" : localStorage.getItem("CorporateleadContactId") == "" ? "0" : localStorage.getItem("CorporateleadContactId")),
            ServicePackageId: parseInt(localStorage.getItem("CorServicePackageId") == null ? "0" : localStorage.getItem("CorServicePackageId") == "" ? "0" : localStorage.getItem("CorServicePackageId")),
            BureauId: parseInt(localStorage.getItem("CorServiceBureauId") == null ? "0" : localStorage.getItem("CorServiceBureauId") == "" ? "0" : localStorage.getItem("CorServiceBureauId")),
            FinancialYearId: document.getElementById("ddlFYYear").value == '' ? parseInt(localStorage.getItem("FinancialYearId") == null ? "0" : localStorage.getItem("FinancialYearId") == "" ? "0" : localStorage.getItem("FinancialYearId")) : document.getElementById("ddlFYYear").value,
            ReportType: 'ALL',
            ReportUploadType: document.getElementById("ddlReportUploadType").value,
            UploadMonth: document.getElementById("ddlUploadMonthFilter").value
        }
        await axios.post(global.APIURL + '/CorporateServiceUploadReportList', FiltrItem)
            .then(res => {
                setCorporateServiceUploadReportList(res.data);
            });
    }

    const getCorporateServiceUploadReportConsumerList = async () => {
        const FiltrItem = {
            CorporateLeadId: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
            CorporateLeadUserId: parseInt(localStorage.getItem("CorporateleadContactId") == null ? "0" : localStorage.getItem("CorporateleadContactId") == "" ? "0" : localStorage.getItem("CorporateleadContactId")),
            ServicePackageId: parseInt(localStorage.getItem("CorServicePackageId") == null ? "0" : localStorage.getItem("CorServicePackageId") == "" ? "0" : localStorage.getItem("CorServicePackageId")),
            BureauId: parseInt(localStorage.getItem("CorServiceBureauId") == null ? "0" : localStorage.getItem("CorServiceBureauId") == "" ? "0" : localStorage.getItem("CorServiceBureauId")),
            FinancialYearId: document.getElementById("ddlFYYear").value == '' ? parseInt(localStorage.getItem("FinancialYearId") == null ? "0" : localStorage.getItem("FinancialYearId") == "" ? "0" : localStorage.getItem("FinancialYearId")) : document.getElementById("ddlFYYear").value,
            ReportType: 'Consumer',
            ReportUploadType: document.getElementById("ddlReportUploadType").value,
            UploadMonth: document.getElementById("ddlUploadMonthFilter").value
        }

        await axios.post(global.APIURL + '/CorporateServiceUploadReportList', FiltrItem)
            .then(res => {
                setCorporateServiceUploadReportConsumerList(res.data);
            });
    }

    const getCorporateServiceUploadReportCommercialList = async () => {
        const FiltrItem = {
            CorporateLeadId: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
            CorporateLeadUserId: parseInt(localStorage.getItem("CorporateleadContactId") == null ? "0" : localStorage.getItem("CorporateleadContactId") == "" ? "0" : localStorage.getItem("CorporateleadContactId")),
            ServicePackageId: parseInt(localStorage.getItem("CorServicePackageId") == null ? "0" : localStorage.getItem("CorServicePackageId") == "" ? "0" : localStorage.getItem("CorServicePackageId")),
            BureauId: parseInt(localStorage.getItem("CorServiceBureauId") == null ? "0" : localStorage.getItem("CorServiceBureauId") == "" ? "0" : localStorage.getItem("CorServiceBureauId")),
            FinancialYearId: document.getElementById("ddlFYYear").value == '' ? parseInt(localStorage.getItem("FinancialYearId") == null ? "0" : localStorage.getItem("FinancialYearId") == "" ? "0" : localStorage.getItem("FinancialYearId")) : document.getElementById("ddlFYYear").value,
            ReportType: 'Commercial',
            ReportUploadType: document.getElementById("ddlReportUploadType").value,
            UploadMonth: document.getElementById("ddlUploadMonthFilter").value
        }

        await axios.post(global.APIURL + '/CorporateServiceUploadReportList', FiltrItem)
            .then(res => {
                setCorporateServiceUploadReportCommercialList(res.data);
            });
    }

    const getCorporateServiceUploadReportMicroFinanceList = async () => {
        const FiltrItem = {
            CorporateLeadId: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
            CorporateLeadUserId: parseInt(localStorage.getItem("CorporateleadContactId") == null ? "0" : localStorage.getItem("CorporateleadContactId") == "" ? "0" : localStorage.getItem("CorporateleadContactId")),
            ServicePackageId: parseInt(localStorage.getItem("CorServicePackageId") == null ? "0" : localStorage.getItem("CorServicePackageId") == "" ? "0" : localStorage.getItem("CorServicePackageId")),
            BureauId: parseInt(localStorage.getItem("CorServiceBureauId") == null ? "0" : localStorage.getItem("CorServiceBureauId") == "" ? "0" : localStorage.getItem("CorServiceBureauId")),
            FinancialYearId: document.getElementById("ddlFYYear").value == '' ? parseInt(localStorage.getItem("FinancialYearId") == null ? "0" : localStorage.getItem("FinancialYearId") == "" ? "0" : localStorage.getItem("FinancialYearId")) : document.getElementById("ddlFYYear").value,
            ReportType: 'Micro-Finance',
            ReportUploadType: document.getElementById("ddlReportUploadType").value,
            UploadMonth: document.getElementById("ddlUploadMonthFilter").value
        }
        await axios.post(global.APIURL + '/CorporateServiceUploadReportList', FiltrItem)
            .then(res => {
                setCorporateServiceUploadReportMicroFinanceList(res.data);
            });
    }

    const ServicesUploadReportTypeStep = (item, item1) => {
        localStorage.setItem("CorServiceReportType", item == null ? '' : item);
        localStorage.setItem("CorServiceUploadStep", item1 == null ? '0' : item1 == '' ? '0' : item1);
        $('#MPServicesUploadReportType').modal('show');
    }

    const ServicesUploadReportTypeStepDetail = async (item1, item2) => {

        const FiltrItem = {
            Id: parseInt(item1 == null ? "0" : item1 == "" ? "0" : item1),
            UploadStepId: parseInt(item2 == null ? "0" : item2 == "" ? "0" : item2)
        }
        await axios.post(global.APIURL + '/CorporateServiceUploadReportStepList', FiltrItem)
            .then(res => {
                let ServicesPackageStepArray = [];
                for (let i = 0; i < res.data.ServicesPackageStepList.length; i++) {
                    let VServicesPackageStepList = res.data.ServicesPackageStepList[i];
                    let VMasterId = VServicesPackageStepList.Id;
                    let VServicesPackageStepFileList = [];
                    VServicesPackageStepFileList = res.data.ServicesPackageStepFileList.filter(x => x.MasterId == VMasterId);
                    let ServicesPackageStepFileArray = [];
                    for (let j = 0; j < VServicesPackageStepFileList.length; j++) {
                        const SItem = {
                            Id: VServicesPackageStepFileList[j].Id,
                            UploadFilePath: VServicesPackageStepFileList[j].UploadFilePath,
                            UploadFileName: VServicesPackageStepFileList[j].UploadFileName
                        }
                        ServicesPackageStepFileArray.push(SItem);
                    }

                    const SItem2 = {
                        Id: VServicesPackageStepList.Id,
                        MasterId: VServicesPackageStepList.MasterId,
                        UploadDate: VServicesPackageStepList.UploadDate,
                        SUploadDate: VServicesPackageStepList.SUploadDate,
                        UploadStep: VServicesPackageStepList.UploadStep,
                        UploadStatusId: VServicesPackageStepList.UploadStatusId,
                        SCreateDate: VServicesPackageStepList.SCreateDate,
                        StatusTitle: VServicesPackageStepList.StatusTitle,
                        ServicesPackageStepFileArray: ServicesPackageStepFileArray
                    }
                    ServicesPackageStepArray.push(SItem2);
                }

                setCorporateServiceUploadReportStepList(ServicesPackageStepArray);
                $('#MPServicesUploadReportStepList').modal('show');
            });
    }

    const ServicesUploadReportTypeStepDetailEdit = async (item1, item2) => {

        const FiltrItem = {
            Id: parseInt(item1 == null ? "0" : item1 == "" ? "0" : item1),
            UploadStepId: parseInt(item2 == null ? "0" : item2 == "" ? "0" : item2)
        }

        await axios.post(global.APIURL + '/CorporateServiceUploadReportStepListEdit', FiltrItem)
            .then(res => {
                if (res.data.ServicesPackageStepList.length > 0) {
                    let VServicesPackageStepList = res.data.ServicesPackageStepList[0];
                    let VMasterId = VServicesPackageStepList.Id;

                    setUploadMonth(VServicesPackageStepList.UploadMonth == null ? CurrentUploadMonthTitle : VServicesPackageStepList.UploadMonth == "" ? CurrentUploadMonthTitle : VServicesPackageStepList.UploadMonth);
                    setUploadDate(VServicesPackageStepList.DUploadDate == null ? "" : VServicesPackageStepList.DUploadDate);
                    setUploadStatusId(VServicesPackageStepList.UploadStatusId == null ? 0 : VServicesPackageStepList.UploadStatusId == "" ? 0 : VServicesPackageStepList.UploadStatusId);
                    setEditRowId(VServicesPackageStepList.Id == null ? 0 : VServicesPackageStepList.Id == "" ? 0 : VServicesPackageStepList.Id)
                    let VServicesPackageStepFileList = [];
                    VServicesPackageStepFileList = res.data.ServicesPackageStepFileList.filter(x => x.MasterId == VMasterId);
                    setCorporateServiceUploadReportStepFileList(VServicesPackageStepFileList);

                    var VStepId = VServicesPackageStepList.UploadStep == null ? 0 : VServicesPackageStepList.UploadStep == "" ? 0 : VServicesPackageStepList.UploadStep;
                    var VReportUploadType = VServicesPackageStepList.ReportUploadType == null ? '' : VServicesPackageStepList.ReportUploadType == "" ? '' : VServicesPackageStepList.ReportUploadType;


                    ServiceStatusList.length = 0;

                    const FiltrItem = {
                        StepId: VStepId,
                        StepType: VReportUploadType
                    }

                    console.log(FiltrItem);

                    axios.post(global.APIURL + '/ServiceStatusList', FiltrItem)
                        .then(res => {
                            setServiceStatusList(res.data);
                        });

                    $('#MPServicesUploadReportStepList').modal('hide');
                    $('#MPServicesUploadReportTypeStepEdit').modal('show');
                }
                else {
                    $('#MPServicesUploadReportStepList').modal('show');
                    setUploadMonth(CurrentUploadMonthTitle);
                    setUploadDate('');
                    setUploadStatusId(0);
                    setEditRowId(0);
                    CorporateServiceUploadReportStepFileList.length = 0;
                }
            });
    }

    const ServicesUploadReportType = async (item) => {
        localStorage.setItem("ReportUploadType", item == null ? '' : item);

        ServiceStatusList.length = 0;

        const FiltrItem = {
            StepId: parseInt(localStorage.getItem("CorServiceUploadStep") == null ? "0" : localStorage.getItem("CorServiceUploadStep") == "" ? "0" : localStorage.getItem("CorServiceUploadStep")),
            StepType: item == null ? "" : item
        }
        await axios.post(global.APIURL + '/ServiceStatusList', FiltrItem)
            .then(res => {
                setServiceStatusList(res.data);
            });

        UploadFileList.length = 0;

        $('#MPServicesUploadReportType').modal('hide');
        $('#MPServicesUploadReportTypeAdd').modal('show');
    }

    const ServicesUploadReportTypeStepHome = async (item1, item2, item3, item4) => {
        localStorage.setItem("CorServiceReportType", item1 == null ? '' : item1);
        localStorage.setItem("CorServiceUploadStep", item2 == null ? '0' : item2 == '' ? '0' : item2);
        localStorage.setItem("CorServiceUploadStepMasterId", item3 == null ? '0' : item3 == '' ? '0' : item3);
        localStorage.setItem("ReportUploadType", item4 == null ? '' : item4);
        UploadFileList.length = 0;

        ServiceStatusList.length = 0;

        const FiltrItem = {
            StepId: parseInt(item2 == null ? "0" : item2 == "" ? "0" : item2),
            StepType: item4 == null ? "" : item4
        }

        await axios.post(global.APIURL + '/ServiceStatusList', FiltrItem)
            .then(res => {
                setServiceStatusList(res.data);
            });

        $('#MPServicesUploadReportTypeStepAdd').modal('show');
    }

    const SaveServiceDetailTypeStep = async (e) => {
        e.preventDefault();
        const SaveServiceDetailTypeItem = {
            MasterId: parseInt(localStorage.getItem("CorServiceUploadStepMasterId") == null ? "0" : localStorage.getItem("CorServiceUploadStepMasterId") == "" ? "0" : localStorage.getItem("CorServiceUploadStepMasterId")),
            UploadStep: parseInt(localStorage.getItem("CorServiceUploadStep") == null ? "0" : localStorage.getItem("CorServiceUploadStep") == "" ? "0" : localStorage.getItem("CorServiceUploadStep")),
            UploadDate: document.getElementById("txtUploadDate").value,
            UploadStatusId: parseInt(document.getElementById("ddlStatus").value == null ? "0" : document.getElementById("ddlStatus").value == "" ? "0" : document.getElementById("ddlStatus").value),
            CreateUser: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
        }

        axios.post(global.APIURL + "/CorporateServiceUploadReportStepSave", SaveServiceDetailTypeItem)
            .then(res => {
                var VRecordInsertId = res.data[0].RecordInsertId;
                var rowcount = 0;
                for (let con = 0; con < UploadFileList.length; con++) {
                    const UploadFileDetail = UploadFileList[con];
                    var VFilePath = UploadFileDetail.FilePath;
                    if (VFilePath != null) {
                        if (VFilePath != '') {
                            const SaveServiceDetailTypeItemArr = {
                                MasterId: VRecordInsertId,
                                UploadFilePath: VFilePath,
                                UploadFileName: UploadFileDetail.FileName,
                                CreateUser: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
                            };

                            axios.post(global.APIURL + '/CorporateServiceUploadReportFileSave', SaveServiceDetailTypeItemArr).then(() => { });
                        }
                    }

                    rowcount++;
                }

                if (UploadFileList.length == rowcount) {
                    alert('Report step upload save successfully.');
                    $('#MPServicesUploadReportTypeStepAdd').modal('hide');

                    getCorporateServiceUploadReportList('');
                    getCorporateServiceUploadReportConsumerList('');
                    getCorporateServiceUploadReportCommercialList('');
                    getCorporateServiceUploadReportMicroFinanceList('');

                    localStorage.removeItem("CorServiceReportType");
                    localStorage.removeItem("CorServiceUploadStep")
                    localStorage.removeItem("CorServiceUploadStepMasterId")
                    localStorage.removeItem("ReportUploadType")
                }
            });
    }

    const SaveServiceDetailType = async (e) => {
        e.preventDefault();
        var VCorServiceReportType = localStorage.getItem("CorServiceReportType") == null ? "" : localStorage.getItem("CorServiceReportType") == "" ? "" : localStorage.getItem("CorServiceReportType");
        var VReportUploadType = localStorage.getItem("ReportUploadType") == null ? "" : localStorage.getItem("ReportUploadType") == "" ? "" : localStorage.getItem("ReportUploadType");
        var VReportTitle = VCorServiceReportType + " " + VReportUploadType + " " + UploadMonth;
        const SaveServiceDetailTypeItem = {
            CorporateLeadId: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
            CorporateLeadUserId: parseInt(localStorage.getItem("CorporateleadContactId") == null ? "0" : localStorage.getItem("CorporateleadContactId") == "" ? "0" : localStorage.getItem("CorporateleadContactId")),
            ServicePackageId: parseInt(localStorage.getItem("CorServicePackageId") == null ? "0" : localStorage.getItem("CorServicePackageId") == "" ? "0" : localStorage.getItem("CorServicePackageId")),
            BureauId: parseInt(localStorage.getItem("CorServiceBureauId") == null ? "0" : localStorage.getItem("CorServiceBureauId") == "" ? "0" : localStorage.getItem("CorServiceBureauId")),
            FinancialYearId: parseInt(document.getElementById("ddlFYYear").value == '' ? parseInt(localStorage.getItem("FinancialYearId") == null ? "0" : localStorage.getItem("FinancialYearId") == "" ? "0" : localStorage.getItem("FinancialYearId")) : document.getElementById("ddlFYYear").value),
            UploadStep: parseInt(localStorage.getItem("CorServiceUploadStep") == null ? "0" : localStorage.getItem("CorServiceUploadStep") == "" ? "0" : localStorage.getItem("CorServiceUploadStep")),
            ReportType: VCorServiceReportType,
            ReportTitle: VReportTitle,
            ReportUploadType: VReportUploadType,
            UploadMonth: document.getElementById("ddlUploadMonth1").value == null ? "" : document.getElementById("ddlUploadMonth1").value == "" ? "" : document.getElementById("ddlUploadMonth1").value,
            UploadDate: document.getElementById("txtUploadDate1").value == null ? "" : document.getElementById("txtUploadDate1").value,
            UploadStatusId: parseInt(document.getElementById("ddlStatus1").value == null ? "0" : document.getElementById("ddlStatus1").value == '' ? "0" : document.getElementById("ddlStatus1").value),
            CreateUser: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
        }

        axios.post(global.APIURL + "/CorporateServiceUploadReportSave", SaveServiceDetailTypeItem)
            .then(res => {
                var VRecordInsertId = res.data[0].RecordInsertId;
                var rowcount = 0;
                for (let con = 0; con < UploadFileList.length; con++) {
                    const UploadFileDetail = UploadFileList[con];
                    var VFilePath = UploadFileDetail.FilePath;
                    if (VFilePath != null) {
                        if (VFilePath != '') {
                            const SaveServiceDetailTypeItemArr = {
                                MasterId: VRecordInsertId,
                                UploadFilePath: VFilePath,
                                UploadFileName: UploadFileDetail.FileName,
                                CreateUser: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
                            };

                            axios.post(global.APIURL + '/CorporateServiceUploadReportFileSave', SaveServiceDetailTypeItemArr).then(() => { });
                        }
                    }

                    rowcount++;
                }

                if (UploadFileList.length == rowcount) {
                    alert('Report upload save successfully.');
                    $('#MPServicesUploadReportTypeAdd').modal('hide');

                    var VCorServiceReportType = localStorage.getItem("CorServiceReportType") == null ? "" : localStorage.getItem("CorServiceReportType") == "" ? "" : localStorage.getItem("CorServiceReportType");
                    if (VCorServiceReportType == 'Consumer') {
                        getCorporateServiceUploadReportList('');
                        getCorporateServiceUploadReportConsumerList('');
                    }
                    else if (VCorServiceReportType == 'Commercial') {
                        getCorporateServiceUploadReportList('');
                        getCorporateServiceUploadReportCommercialList('');
                    }
                    else if (VCorServiceReportType == 'Micro-Finance') {
                        getCorporateServiceUploadReportList('');
                        getCorporateServiceUploadReportMicroFinanceList('');
                    }

                    localStorage.removeItem("CorServiceReportType");
                    localStorage.removeItem("CorServiceUploadStep")
                    localStorage.removeItem("CorServiceUploadStepMasterId")
                    localStorage.removeItem("ReportUploadType")
                }
            });
    }

    const ServiceDetailTypeStepFileDelete = async (item1) => {

        const FiltrItem = {
            Id: parseInt(item1 == null ? "0" : item1 == "" ? "0" : item1),
        }
        await axios.post(global.APIURL + '/CorporateServiceUploadReportStepFileDelete', FiltrItem)
            .then(res => {
                alert('Report step upload file delete successfully.');
                setCorporateServiceUploadReportStepFileList(res.data);
            });
    }

    const ServiceDetailTypeStepUpdate = async (e) => {
        e.preventDefault();
        const SaveServiceDetailTypeItem = {
            Id: parseInt(EditRowId == null ? "0" : EditRowId == "" ? "0" : EditRowId),
            UploadDate: UploadDate,
            UploadStatusId: UploadStatusId,
            CreateUser: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
        }

        axios.post(global.APIURL + "/CorporateServiceUploadReportStepUpdate", SaveServiceDetailTypeItem)
            .then(res => {
                var VRecordInsertId = parseInt(EditRowId == null ? "0" : EditRowId == "" ? "0" : EditRowId);
                var rowcount = 0;
                for (let con = 0; con < UploadFileList.length; con++) {
                    const UploadFileDetail = UploadFileList[con];
                    var VFilePath = UploadFileDetail.FilePath;
                    if (VFilePath != null) {
                        if (VFilePath != '') {
                            const SaveServiceDetailTypeItemArr = {
                                MasterId: VRecordInsertId,
                                UploadFilePath: VFilePath,
                                UploadFileName: UploadFileDetail.FileName,
                                CreateUser: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
                            };

                            axios.post(global.APIURL + '/CorporateServiceUploadReportFileSave', SaveServiceDetailTypeItemArr).then(() => { });
                        }
                    }

                    rowcount++;
                }

                if (UploadFileList.length == rowcount) {
                    alert('Report step upload update successfully.');
                    $('#MPServicesUploadReportTypeStepEdit').modal('hide');

                    getCorporateServiceUploadReportList('');
                    getCorporateServiceUploadReportConsumerList('');
                    getCorporateServiceUploadReportCommercialList('');
                    getCorporateServiceUploadReportMicroFinanceList('');

                    localStorage.removeItem("CorServiceReportType");
                    localStorage.removeItem("CorServiceUploadStep")
                    localStorage.removeItem("CorServiceUploadStepMasterId")
                    localStorage.removeItem("ReportUploadType")
                }
            });
    }

    return (
        <React.Fragment>
            <Commheader />
            <div className="page-content">

                <div className="container-fluid">

                    <div className="modal fade bd-example-modal-sm" id="MPServicesUploadReportStepList" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div>
                                        <h5 className="modal-title" id="exampleModalLabel">Services Upload Report Step Detail</h5>
                                    </div>
                                    <div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <Row>
                                        <Col lg={12} >
                                            <div className='maindasboardtableeditnew mt-0 verticalaligntable'>
                                                <table className="table">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col" className='text-center'>#</th>
                                                            <th scope="col" className='text-center'>Upload Date</th>
                                                            <th scope="col" className="uploadfilewidth">Upload Files</th>
                                                            <th scope="col" className='text-center'>Status</th>
                                                            <th scope="col" className='text-center'>Create Date</th>
                                                            <th scope="col" className='text-center'>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {CorporateServiceUploadReportStepList.map((item, index) => (

                                                            <tr key={index}>
                                                                <td className='text-center'>{index + 1}</td>
                                                                <td className='text-center'>{item.SUploadDate}</td>
                                                                {/* <td className='text-center'><a onClick={e => ServicesUploadReportTypeStepFileDetail(item.Id)}>View Upload File</a></td> */}
                                                                <td>

                                                                    {item.ServicesPackageStepFileArray.map((item2, idx) => (
                                                                        <p key={idx} className="badge badge-soft-primary font-12 mb-1 me-1"> {idx + 1}. <a href={item2.UploadFilePath} target="_blank" rel="noopener noreferrer">{item2.UploadFileName}</a></p>
                                                                    ))}

                                                                </td>
                                                                <td className='text-center'>{item.StatusTitle}</td>
                                                                <td className='text-center'>{item.SCreateDate}</td>
                                                                <td className='text-center'><a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepDetailEdit(item.Id, item.UploadStep)}>Edit</a></td>
                                                            </tr>
                                                        ))}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade bd-example-modal-sm" id="MPServicesUploadReportType" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div>
                                        <h5 className="modal-title" id="exampleModalLabel">Select Report Type</h5>
                                    </div>
                                    <div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <Row>
                                        <Col lg={7} className="mt-2">
                                            <button className="btn btn_success btn-sm" id="btnCreditInfoUpload" data-bs-dismiss="modal" aria-label="Close" onClick={e => ServicesUploadReportType('Credit Info Upload')}>Credit Info Upload</button>
                                        </Col>
                                        <Col lg={5} className="mt-2">
                                            <button className="btn btn_success btn-sm" id="btnOLMUpload" data-bs-dismiss="modal" aria-label="Close" onClick={e => ServicesUploadReportType('OLM Upload')}>OLM Upload</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade bd-example-modal-sm" id="MPServicesUploadReportTypeStepAdd" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div>
                                        <h5 className="modal-title" id="exampleModalLabel">Add Services Upload Report Step</h5>
                                    </div>
                                    <div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <Row>
                                        <Col lg={12}>
                                            <label className="form-label">Upload Month</label>
                                            <select className="form-select" name="ddlUploadMonth" id="ddlUploadMonth" onChange={(e) => setUploadMonth(e.target.value)}>
                                                {UploadMonthList.map((item) => (
                                                    <option key={item.UploadMonth} value={item.UploadMonth} selected={CurrentUploadMonthTitle == item.UploadMonth}>
                                                        {item.UploadMonth}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="mb-2">
                                                <label className="form-label">Upload Date</label>
                                                <Input type="date" className="form-control" id="txtUploadDate" onChange={(e) => setUploadDate(e.target.value)} />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="mb-2">
                                                <label className="form-label">Upload File</label>
                                                <input type="file" className="form-control form-control" multiple id="FUDocumentFilePath" onChange={DocumentfetchPost} />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <label className="form-label">Status</label>
                                            <select className="form-select" name="ddlStatus" id="ddlStatus" onChange={(e) => setUploadStatusId(e.target.value)}>

                                                {ServiceStatusList.map((item) => (
                                                    <option key={item.Id} value={item.Id}>
                                                        {item.StatusTitle}
                                                    </option>
                                                ))}

                                            </select>
                                        </Col>
                                        <Col lg={12} className="mt-3">
                                            <button className="btn btn_success btn-sm" id="btnsave" onClick={e => SaveServiceDetailTypeStep(e)} >Save</button>
                                            <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade bd-example-modal-sm" id="MPServicesUploadReportTypeAdd" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div>
                                        <h5 className="modal-title" id="exampleModalLabel">Add Services Upload Report</h5>
                                    </div>
                                    <div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <Row>
                                        <Col lg={12}>
                                            <label className="form-label">Upload Month</label>
                                            <select className="form-select" name="ddlUploadMonth1" id="ddlUploadMonth1" onChange={(e) => setUploadMonth(e.target.value)}>
                                                {UploadMonthList.map((item) => (
                                                    <option key={item.UploadMonth} value={item.UploadMonth} selected={CurrentUploadMonthTitle == item.UploadMonth}>
                                                        {item.UploadMonth}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="mb-2">
                                                <label className="form-label">Upload Date</label>
                                                <Input type="date" className="form-control" id="txtUploadDate1" onChange={(e) => setUploadDate(e.target.value)} />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="mb-2">
                                                <label className="form-label">Upload File</label>
                                                <input type="file" className="form-control form-control" multiple id="FUDocumentFilePath1" onChange={DocumentfetchPost} />
                                            </div>
                                        </Col>

                                        <Col lg={12}>
                                            <label className="form-label">Status</label>
                                            <select className="form-select" name="ddlStatus1" id="ddlStatus1" onChange={(e) => setUploadStatusId(e.target.value)}>

                                                {ServiceStatusList.map((item) => (
                                                    <option key={item.Id} value={item.Id}>
                                                        {item.StatusTitle}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col lg={12} className="mt-3">
                                            <button className="btn btn_success btn-sm" id="btnsave" onClick={e => SaveServiceDetailType(e)} >Save</button>
                                            <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade bd-example-modal-sm" id="MPServicesUploadReportTypeStepEdit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div>
                                        <h5 className="modal-title" id="exampleModalLabel">Edit Upload Report Step</h5>
                                    </div>
                                    <div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <Row>
                                        <Col lg={12} className="mb-2">
                                            <label className="form-label">Upload Month</label>
                                            <Input type="text" className="form-control" id="txtUploadMonth" value={UploadMonth} readOnly />
                                        </Col>
                                        <Col lg={12} className="mb-2">
                                            <div className="mb-2">
                                                <label className="form-label">Upload Date</label>
                                                <Input type="date" className="form-control" id="txtUploadDate" value={UploadDate} onChange={(e) => setUploadDate(e.target.value)} />
                                            </div>
                                        </Col>
                                        <Col lg={12} className="mb-2">
                                            <div className="mb-2">
                                                <label className="form-label">Upload File</label>
                                                <input type="file" className="form-control form-control" multiple id="FUDocumentFilePath" onChange={DocumentfetchPost} />
                                            </div>
                                        </Col>

                                        <Col lg={12} className="mt-2">
                                            <h5>Upload File List</h5>
                                            <div className='filuploadmain'>
                                                <table className="table">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">Upload Files</th>
                                                            <th scope="col" className='text-center'>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {CorporateServiceUploadReportStepFileList.map((item1, index1) => (
                                                            <tr key={index1}>
                                                                <td><a href={item1.UploadFilePath} target="_blank" rel="noopener noreferrer">{item1.UploadFileName}</a></td>
                                                                <td className='text-center'><a className="text-danger" onClick={e => ServiceDetailTypeStepFileDelete(item1.Id)} ><i className="mdi mdi-close font-size-18"></i></a></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>

                                        <Col lg={12}>
                                            <label className="form-label">Status</label>
                                            <select className="form-select" name="ddlStatusEdit" Id="ddlStatusEdit" onChange={(e) => setUploadStatusId(e.target.value)}>

                                                {ServiceStatusList.map((item) => (
                                                    <option key={item.Id} value={item.Id} selected={UploadStatusId === item.Id}>
                                                        {item.StatusTitle}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col lg={12} className="mt-3">
                                            <button className="btn btn_success btn-sm" id="btnsave" onClick={e => ServiceDetailTypeStepUpdate(e)} >Update</button>
                                            <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <div className="">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="maincommontitlefordahboard">
                                            Services Report File Upload
                                        </p>
                                        <select className="form-select" Id="ddlFYYear" name="ddlFYYear" onChange={e => ddlReportFilterchange()}>
                                            {FinancialYearList?.map((item, idx) => (
                                                <option value={item.Id} key={idx}>
                                                    {item.FinancialYear}
                                                </option>
                                            ))}
                                        </select>
                                    </div>


                                </div>
                            </div>
                            <div className="card p-0">
                                <div className="card-body">
                                    <Row>
                                        <div className="col-md-8">
                                            <ul className="nav client-detail-firm nav-tabs-new-edit border-0 nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link border-0 active" id="services-type-all-tab" data-bs-toggle="tab" data-bs-target="#ServicesTypeAll" type="button" role="tab" aria-controls="ServicesTypeAll" aria-selected="true">
                                                        <label className="form-check-label">{" "}ALL</label>
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link border-0" id="services-type-consumer-tab" data-bs-toggle="tab" data-bs-target="#ServicesTypeConsumer" type="button" role="tab" aria-controls="ServicesTypeConsumer" aria-selected="false">
                                                        <label className="form-check-label">{" "}Consumer</label>
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link border-0" id="services-type-commercial-tab" data-bs-toggle="tab" data-bs-target="#ServicesTypeCommercial" type="button" role="tab" aria-controls="ServicesTypeCommercial" aria-selected="false">
                                                        <label className="form-check-label">{" "}Commercial</label>
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link border-0" id="services-type-micro-finance-tab" data-bs-toggle="tab" data-bs-target="#ServicesTypeMicroFinance" type="button" role="tab" aria-controls="ServicesTypeMicroFinance" aria-selected="false">
                                                        <label className="form-check-label">{" "}Micro-Finance</label>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-select btn-sm" Id="ddlUploadMonthFilter" name="ddlUploadMonthFilter" onChange={e => ddlReportFilterchange()}>
                                                <option value={""}>ALL Upload Month</option>
                                                {UploadMonthList.map((item) => (
                                                    <option key={item.UploadMonth} value={item.UploadMonth}>
                                                        {item.UploadMonth}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <select className="form-select btn-sm" Id="ddlReportUploadType" name="ddlReportUploadType" onChange={e => ddlReportFilterchange()}>
                                                <option value={""}>ALL Upload Type</option>
                                                <option value={"Credit Info Upload"}>Credit Info Upload</option>
                                                <option value={"OLM Upload"}>OLM Upload</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12 mt-3 mb-3">
                                            <hr></hr>
                                        </div>

                                        <div className="col-md-12">

                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="ServicesTypeAll" role="tabpanel" aria-labelledby="services-type-all-tab">
                                                    <Row>
                                                        <div className="col-md-10">
                                                            <ul className="back_color_info editback_color_info mb-0">
                                                                <li><span className="consumer-row-color"></span> Consumer</li>
                                                                <li><span className="commercial-row-color"></span> Commercial</li>
                                                                <li><span className="microfinance-row-color"></span> Micro-Finance</li>
                                                            </ul>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className="col-md-12">
                                                            <div className='maindasboardtableeditnew verticalaligntable'>
                                                                <table className="table">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th scope="col" className='text-center'>#</th>
                                                                            <th scope="col">Title</th>
                                                                            <th scope="col" className='text-center'>Type</th>
                                                                            <th scope="col" className='text-center'>Report Type</th>
                                                                            <th scope="col" className='text-center'>Upload Month</th>
                                                                            <th scope="col" className='text-center'>Step Detail</th>
                                                                            {/* <th scope="col" className='text-center'>Step-1<br />Uploading</th>
                                                                            <th scope="col" className='text-center'>Step-2<br />hecking</th>
                                                                            <th scope="col" className='text-center'>Step-3<br />Processing</th>
                                                                            <th scope="col" className='text-center'>Step-4<br />Confirmation</th>
                                                                            <th scope="col" className='text-center'>Step-5<br />Reject/Success</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {CorporateServiceUploadReportList?.map((item, idx) => (
                                                                            <tr key={idx} className={item.ReportType == 'Consumer' ? 'consumer-row-color' : item.ReportType == 'Commercial' ? 'commercial-row-color' : item.ReportType == 'Micro-Finance' ? 'microfinance-row-color' : ''}>
                                                                                <td className='text-center'>{idx + 1}</td>
                                                                                <td className="maintititletablewidth">{item.ReportTitle}</td>
                                                                                <td className='text-center'>{item.ReportType}</td>
                                                                                <td className='text-center'>{item.ReportUploadType}</td>
                                                                                <td className='text-center'>{item.UploadMonth}</td>
                                                                                <td className='text-center'>
                                                                                    {item.ReportUploadType == "Credit Info Upload" ?
                                                                                        <div className="gridviewtable">
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-1</label>
                                                                                                {item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep(item.ReportType, 1,)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-2</label>
                                                                                                {item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 2, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-3</label>
                                                                                                {item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 3, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-4</label>
                                                                                                {item.Step4Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 4, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 4)}>{item.Step4Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-5</label>
                                                                                                {item.Step5Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 5, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 5)}>{item.Step5Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="gridviewtable">
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-1</label>
                                                                                                {item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep(item.ReportType, 1)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-2</label>
                                                                                                {item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 2, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-3</label>
                                                                                                {item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 3, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                        </div>}
                                                                                </td>
                                                                                {/* <td className='text-center'></td>
                                                                                <td className='text-center'></td>
                                                                                <td className='text-center'></td>
                                                                                <td className='text-center'></td> */}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                                <div className="tab-pane fade" id="ServicesTypeConsumer" role="tabpanel" aria-labelledby="services-type-consumer-tab">
                                                    <div className="d-flex align-items-end justify-content-end">
                                                        <a className="btn btn-primary btn-sm   ms-2" onClick={e => ServicesUploadReportTypeStep('Consumer', 1)}><i className="mdi mdi-plus align-middle me-1"></i>Add New</a>
                                                    </div>
                                                    <Row>
                                                        <div className="col-md-12">
                                                            <div className='maindasboardtableeditnew verticalaligntable'>
                                                                <table className="table">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th scope="col" className='text-center'>#</th>
                                                                            <th scope="col">Title</th>
                                                                            <th scope="col" className='text-center'>Report Type</th>
                                                                            <th scope="col" className='text-center'>Upload Month</th>
                                                                            <th scope="col" className='text-center'>Step Detail</th>
                                                                            {/* <th scope="col" className='text-center'>Step-1<br />Uploading</th>
                                                                            <th scope="col" className='text-center'>Step-2<br />hecking</th>
                                                                            <th scope="col" className='text-center'>Step-3<br />Processing</th>
                                                                            <th scope="col" className='text-center'>Step-4<br />Confirmation</th>
                                                                            <th scope="col" className='text-center'>Step-5<br />Reject/Success</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {CorporateServiceUploadReportConsumerList?.map((item, idx) => (
                                                                            <tr key={idx}>
                                                                                <td className='text-center'>{idx + 1}</td>
                                                                                <td className="maintititletablewidth">{item.ReportTitle}</td>
                                                                                <td className='text-center'>{item.ReportUploadType}</td>
                                                                                <td className='text-center'>{item.UploadMonth}</td>
                                                                                <td className='text-center'>
                                                                                    {item.ReportUploadType == "Credit Info Upload" ?
                                                                                        <div className="gridviewtable">
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-1</label>
                                                                                                {item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep(item.ReportType, 1,)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-2</label>
                                                                                                {item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 2, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-3</label>
                                                                                                {item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 3, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-4</label>
                                                                                                {item.Step4Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 4, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 4)}>{item.Step4Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-5</label>
                                                                                                {item.Step5Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 5, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 5)}>{item.Step5Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="gridviewtable">
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-1</label>
                                                                                                {item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep(item.ReportType, 1)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-2</label>
                                                                                                {item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 2, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-3</label>
                                                                                                {item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 3, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                        </div>}
                                                                                </td>
                                                                                {/* <td className='text-center'>{item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep('Consumer', 1)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Consumer', 2, item.Id, item.ReportType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Consumer', 3, item.Id, item.ReportType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step4Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Consumer', 4, item.Id, item.ReportType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 4)}>{item.Step4Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step5Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Consumer', 5, item.Id, item.ReportType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 5)}>{item.Step5Status} <i className="las la-pen"></i></a>}</td> */}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                                <div className="tab-pane fade" id="ServicesTypeCommercial" role="tabpanel" aria-labelledby="services-type-commercial-tab">
                                                    <div className="d-flex align-items-end justify-content-end">
                                                        <a className="btn btn-primary btn-sm  ms-2" onClick={e => ServicesUploadReportTypeStep('Commercial', 1)}><i className="mdi mdi-plus align-middle me-1"></i>Add New</a>
                                                    </div>
                                                    <Row>
                                                        <div className="col-md-12">
                                                            <div className='maindasboardtableeditnew verticalaligntable'>
                                                                <table className="table">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th scope="col" className='text-center'>#</th>
                                                                            <th scope="col">Title</th>
                                                                            <th scope="col" className='text-center'>Report Type</th>
                                                                            <th scope="col" className='text-center'>Upload Month</th>
                                                                            <th scope="col" className='text-center'>Step Detail</th>
                                                                            {/* <th scope="col" className='text-center'>Step-1<br />Uploading</th>
                                                                            <th scope="col" className='text-center'>Step-2<br />hecking</th>
                                                                            <th scope="col" className='text-center'>Step-3<br />Processing</th>
                                                                            <th scope="col" className='text-center'>Step-4<br />Confirmation</th>
                                                                            <th scope="col" className='text-center'>Step-5<br />Reject/Success</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {CorporateServiceUploadReportCommercialList?.map((item, idx) => (
                                                                            <tr key={idx}>
                                                                                <td className='text-center'>{idx + 1}</td>
                                                                                <td className="maintititletablewidth">{item.ReportTitle}</td>
                                                                                <td className='text-center'>{item.ReportUploadType}</td>
                                                                                <td className='text-center'>{item.UploadMonth}</td>
                                                                                <td className='text-center'>
                                                                                    {item.ReportUploadType == "Credit Info Upload" ?
                                                                                        <div className="gridviewtable">
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-1</label>
                                                                                                {item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep(item.ReportType, 1,)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-2</label>
                                                                                                {item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 2, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-3</label>
                                                                                                {item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 3, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-4</label>
                                                                                                {item.Step4Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 4, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 4)}>{item.Step4Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-5</label>
                                                                                                {item.Step5Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 5, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 5)}>{item.Step5Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="gridviewtable">
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-1</label>
                                                                                                {item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep(item.ReportType, 1)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-2</label>
                                                                                                {item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 2, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-3</label>
                                                                                                {item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 3, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                        </div>}
                                                                                </td>
                                                                                {/* <td className='text-center'>{item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep('Consumer', 1)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Commercial', 2, item.Id)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Commercial', 3, item.Id)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step4Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Commercial', 4, item.Id)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 4)}>{item.Step4Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step5Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Commercial', 5, item.Id)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 5)}>{item.Step5Status} <i className="las la-pen"></i></a>}</td> */}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                                <div className="tab-pane fade" id="ServicesTypeMicroFinance" role="tabpanel" aria-labelledby="services-type-micro-finance-tab">
                                                    <div className="d-flex align-items-end justify-content-end">
                                                        <a className="btn btn-primary btn-sm  ms-2" onClick={e => ServicesUploadReportTypeStep('Micro-Finance', 1)}><i className="mdi mdi-plus align-middle me-1"></i>Add New</a>
                                                    </div>
                                                    <Row>
                                                        <div className="col-md-12">
                                                            <div className='maindasboardtableeditnew verticalaligntable'>
                                                                <table className="table">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th scope="col" className='text-center'>#</th>
                                                                            <th scope="col">Title</th>
                                                                            <th scope="col" className='text-center'>Report Type</th>
                                                                            <th scope="col" className='text-center'>Upload Month</th>
                                                                            <th scope="col" className='text-center'>Step Detail</th>
                                                                            {/* <th scope="col" className='text-center'>Step-1<br />Uploading</th>
                                                                            <th scope="col" className='text-center'>Step-2<br />hecking</th>
                                                                            <th scope="col" className='text-center'>Step-3<br />Processing</th>
                                                                            <th scope="col" className='text-center'>Step-4<br />Confirmation</th>
                                                                            <th scope="col" className='text-center'>Step-5<br />Reject/Success</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {CorporateServiceUploadReportMicroFinanceList?.map((item, idx) => (
                                                                            <tr key={idx}>
                                                                                <td className='text-center'>{idx + 1}</td>
                                                                                <td className="maintititletablewidth">{item.ReportTitle}</td>
                                                                                <td className='text-center'>{item.ReportUploadType}</td>
                                                                                <td className='text-center'>{item.UploadMonth}</td>
                                                                                <td className='text-center'>
                                                                                    {item.ReportUploadType == "Credit Info Upload" ?
                                                                                        <div className="gridviewtable">
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-1</label>
                                                                                                {item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep(item.ReportType, 1,)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-2</label>
                                                                                                {item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 2, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-3</label>
                                                                                                {item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 3, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-4</label>
                                                                                                {item.Step4Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 4, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 4)}>{item.Step4Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-5</label>
                                                                                                {item.Step5Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 5, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 5)}>{item.Step5Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="gridviewtable">
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-1</label>
                                                                                                {item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep(item.ReportType, 1)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-2</label>
                                                                                                {item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 2, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <label className="form-label d-block">Step-3</label>
                                                                                                {item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome(item.ReportType, 3, item.Id, item.ReportUploadType)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}
                                                                                            </div>
                                                                                        </div>}
                                                                                </td>
                                                                                {/* <td className='text-center'>{item.Step1Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStep('Consumer', 1)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 1)}>{item.Step1Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step2Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Micro-Finance', 2, item.Id)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 2)}>{item.Step2Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step3Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Micro-Finance', 3, item.Id)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 3)}>{item.Step3Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step4Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Micro-Finance', 4, item.Id)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 4)}>{item.Step4Status} <i className="las la-pen"></i></a>}</td>
                                                                                <td className='text-center'>{item.Step5Count == 0 ? <a className="btn btn-primary btn-sm font-14 Bureaubtn" onClick={e => ServicesUploadReportTypeStepHome('Micro-Finance', 5, item.Id)}>Add New</a> : <a className="badge badge-soft-primary font-12" onClick={e => ServicesUploadReportTypeStepDetail(item.Id, 5)}>{item.Step5Status} <i className="las la-pen"></i></a>}</td> */}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </div>

                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Services;
