import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'


import axios from 'axios'
//import Apaexlinecolumn from "pages/AllCharts/apex/apaexlinecolumn";
import ReactApexChart from "react-apexcharts"
import e from 'cors';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const BureauHelpLink = () => {

    const [BureauList, setBureauList] = useState([])
    const [BureauHelpLinkList, setBureauHelpLinkList] = useState([])

    useEffect(() => {
        getBureauHelpData();
    }, []);

    const getBureauHelpData = () => {
        axios.post(global.APIURL + '/BureauHelpLinkList')
            .then(res => {
                console.log(res.data);
                if (res.data.length > 0) {
                    setBureauList(res.data.BureauList);
                    setBureauHelpLinkList(res.data.BureauHelpLinkList);
                }
            });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | KC Corporate Admin</title>
                </MetaTags>
                <div className="container-fluid">
                    <Row className="mb-3">
                        <Col className="col-lg-8 col-md-6 col-6">
                            <div className="breadcrum ps-0">
                                <CardTitle className="mb-0">Bureau Help Link</CardTitle>
                            </div>
                        </Col>
                        <Col className="col-lg-4 co-md-6 col-6">
                        </Col>
                    </Row>
                    <Row>
                        {BureauList.map((item, idx) => (
                            <Col className={BureauList.length == 1 ? "col-12" : "col-6"} key={idx}>
                                <Card className="heighfixdashbiardnew">
                                    <CardBody>
                                        <Row>
                                            <div className="col-lg-12 mb-2 col"><div className="card-title newtitaleeditburee"> {item.Title}  </div></div>
                                            <Col xl="12">
                                                <div className="mainnewcategeroiesbureu mb-3">

                                                    <div className="maindatauserbuera">
                                                        <div className="maindatdestailsbureau" >
                                                            <div className="">
                                                                <p className="maintitlebureau text-center">#</p>
                                                            </div>
                                                            <div className="">
                                                                <p className="maintitlebureau">Title</p>
                                                            </div>
                                                            <div className="">
                                                                <p className="maintitlebureau">Link</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {BureauHelpLinkList.filter(x => x.BureauId == item.BId).map((item1, idx1) => (
                                                    <div className="mainnewcategeroiesbureu mb-3" key={idx1} id="bureautable">

                                                        <div className="maindatauserbuera">
                                                            <div className="maindatdestailsbureau" >
                                                                <div className="">
                                                                    <p className="maintitlebureau text-center">{idx1 + 1}</p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="maintitlebureau">{item1.BureauHelpLinkTitle}</p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="maintitlebureau">{item1.BureauHelpLink == "Link" ? <a href={item1.BureauHelpLink} target="_blank" rel="noopener noreferrer">{item1.BureauHelpLink}</a> : <a href={item1.BureauHelpLink} target="_blank" rel="noopener noreferrer">{item1.BureauHelpLinkFileName}</a>}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                </div>
            </div>

        </React.Fragment>
    );

}

export default BureauHelpLink;
