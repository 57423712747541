import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import Commheader from "../../../components/HorizontalLayout/Header";
import PlacholderImgC from "assets/images/favicon.png";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';


const Services = () => {
  const history = useHistory();
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CompanyCityId, setCompanyCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Branchcode, setBranchcode] = useState("");
  const [CompanyList, setCompanyList] = useState([]);
  const [NetworkSource, setNetworkSource] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkType, setNetworkType] = useState("");
  const [DistrictName,setDistrictName]=useState([]);
  const [TehsilName,setTehsilName]=useState([]);
  const [CityName,setCityName]=useState([]);
  
  
  const [LeadContactList, setLeadContactList] = useState([]);
  const [Address, setAddress] = useState("");
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [StateName, setStateName] = useState("");
  const [EditcorLeadId, setcorEditLeadId] = useState("");


  const [Email, setEmail] = useState([]);
  const [Name, setName] = useState([]);
  const [MobileNo, setMobileNo] = useState([]);
  const [Designation, setDesignation] = useState([]);

  const [EmailIdd, setmEmailId] = useState([]);
  const [BureauList, setBureauList] = useState([]);
  const [CorporateServicesPackageList, setCorporateServicesPackageList] = useState([])
  const [BureauDataList,setBureauDataList]=useState([]);
  const [CorSerPackageList ,setCorSerPackageList ]=useState([]);
  const [CorporateServicesPackageBureau ,setCorporateServicesPackageBureau]=useState([]);
   const [CorporateServicesPackagePaymentList, setCorporateServicesPackagePaymentList] = useState([])

  let CoLeadId = localStorage.getItem("CorporateleadId");

  let { id } = useParams();
 

  useEffect(() => {
   
    getServiceDetails();   
    getCorporateServicesPackageList();
    getCorporateServicesPackagepaymentList();
  }, []);

const getServiceDetails=async()=>{
    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const SData = {
        "Id": VCorporateleadId
      }
  
    axios.post(global.APIURL + '/CorporateleadclientDetails ', SData)
    .then(res => {
      
      if(res.data.Result[0].length>0){
        setNetworkSource(res.data.Result[0].ClientDetails[0].CompanyName);
        setNetworkType(res.data.Result[0].ClientDetails[0].ChannelTypeName);
        setAddress(res.data.Result[0].ClientDetails[0].Address);
        setStateName(res.data.Result[0].ClientDetails[0].StateName);
        setDistrictName(res.data.Result[0].ClientDetails[0].districtName);
        setTehsilName(res.data.Result[0].ClientDetails[0].TehsilName);
        setCityName(res.data.Result[0].ClientDetails[0].CityName);        
        setPincode(res.data.Result[0].ClientDetails[0].Pincode);
        setBranchcode(res.data.Result[0].ClientDetails[0].Branchcode);
        setmEmailId(res.data.Result[0].ClientDetails[0].EmailId);
        setLeadContactList(res.data.Result[0].de);        
      }
    });
}

const ManageRedirection = (item1,item2) => {

  localStorage.setItem("CorporateServiceBureauId", parseInt(item2.VBId == null ? '0' : item2.VBId == '' ? '0' : item2.VBId));
  localStorage.setItem("CorporateServiceId", parseInt(item1.ServicesPackageId == null ? '0' : item1.ServicesPackageId == '' ? '0' : item1.ServicesPackageId));
  localStorage.setItem("CorporateServicePackageId", parseInt(item1.VId == null ? '0' : item1.VId == '' ? '0' : item1.VId));
   
  window.open('/ServiceMonthlyDues ', '_blank');

}

  const getCorporateServicesPackageList = async () => {
    let CorporateLeadId =  localStorage.getItem("CorporateleadId");
    const CorporateData = {
      Id: CorporateLeadId,
    }
   await axios.post(global.APIURL + '/CorporateServicesPackageBureau', CorporateData)
      .then(res => {
       // setCorporateServicesPackageList(res.data.ServicesPackage);
       // setCorporateServicesPackageBureau(res.data.ServicesPackageBureau);
        let SArray = [];       
        for (let i = 0; i < res.data.ServicesPackage.length; i++) {   
          let VCorporateServicesPackageList = res.data.ServicesPackage[i];
          let VSerPackageId = VCorporateServicesPackageList.ServicesPackageId;
          let SerBureauList = [];
          SerBureauList = res.data.ServicesPackageBureau.filter(x => x.ServiceId == VSerPackageId);
          let BArray=[];
          for (let j = 0; j < SerBureauList.length; j++)
           {
            const SItem={
              VBId:SerBureauList[j].BId,
              VServiceId: SerBureauList[j].ServiceId,
              VTitle: SerBureauList[j].Title
            }
           BArray.push(SItem);                   
          }       
         const SItem2 = {
            VId: VCorporateServicesPackageList.Id,
            CorporateleadId: res.data.ServicesPackage[i].CorporateleadId,
            ServicesPackageId: res.data.ServicesPackage[i].ServicesPackageId,
            ServicesPackageType: res.data.ServicesPackage[i].ServicesPackageType,
            PlanAmount: res.data.ServicesPackage[i].PlanAmount,
            TotalAmount: res.data.ServicesPackage[i].TotalAmount,
            TotalRecivedAmount: res.data.ServicesPackage[i].TotalRecivedAmount,
            TotalDueAmount: res.data.ServicesPackage[i].TotalDueAmount,
            PaymentStaus: res.data.ServicesPackage[i].PaymentStaus,
            PaymentType: res.data.ServicesPackage[i].PaymentType,
            ServiceTitle: res.data.ServicesPackage[i].ServiceTitle,
            BArray: BArray
          }
          SArray.push(SItem2);
          setCorSerPackageList(SArray);        
        }
      });
  }
  const editRecord = (SerPackageId) => {
    
    let id = SerPackageId
    localStorage.setItem("CorporateServiceId", SerPackageId)
    let path = `Services/SerPackageDetail/:${id}`;
    window.location.href=path
  }
  
  const exportPDF=()=>{

    const unit = "pt";
    const size = "A2"; 
    const orientation = "portrait"; 

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Category List Report";
    const headers = [["#","Category"]];

    // const data = records.map((item,index)=> [index+1,item.Title]);

    // let content = {
    //   startY: 50,
    //   head: headers,
    //   body: data
    // };

    // doc.text(title, marginLeft, 40);
    // doc.autoTable(content);
    // doc.save("CategoryReport.pdf")
  }

  const getCorporateServicesPackagepaymentList = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const CorporatepaymentData = {
      Id: VCorporateleadId,
    }
    axios.post(global.APIURL + '/CorporateLeadServiceppayment', CorporatepaymentData)
      .then(res => {
        setCorporateServicesPackagePaymentList(res.data);
      });
  }

const ManageServiceActivitiesRecord = (SerPackageId, CorleadId) => {

    localStorage.setItem("CorporateLeadId", CorleadId)
    localStorage.setItem("CorporateServiceId", SerPackageId);
    let path = `CorporateClientServiceActivities`;
   // let npath=path.replace('EditRegisterClient/','')
    history.push(path);
  }
  return (
    <React.Fragment>
      <Commheader />
      <div className="page-content">
        <MetaTags>
          <title>Corporate Service Detail | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">

        <div className="row">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Services Package</p>
              

                  {/* <div className='maindasboardtableeditnew'>
                          <table className="table mt-0 mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col">Services Package</th>
                                <th scope="col" className='text-center'>Type</th>
                                <th scope="col" className='text-center'>Due Date</th>
                                <th scope="col" className='text-center'>Amount</th>
                                <th scope="col" className='text-center'>Gst Amount</th>
                                <th scope="col" className='text-center'>Total Amount</th>
                                <th scope="col" className='text-center'>Payment Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {CorporateServicesPackagePaymentList?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td>{item.ServiceTitle}</td>
                                  <td className='text-center'>{item.ServicesPackageType}</td>
                                  <td className='text-center'>{item.DueDate}</td>
                                  <td className='text-center'>{item.Amount}</td>
                                  <td className='text-center'>{item.GstAmount}</td>
                                  <td className='text-center'>{item.TotalAmount}</td>
                                  <td className='text-center'>{item.PaymentStatus}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div> */}


                  <div className='maindasboardtableeditnew'>
                          <table className="table">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col">Services Package</th>
                                <th scope="col" className='text-center'>Type</th>
                                <th scope="col" className='text-center'>Price</th>
                                <th scope="col" className='text-center'>Total Amount</th>
                                <th scope="col" className='text-center'>Recived Amount</th>
                                <th scope="col" className='text-center'>Due Amount</th>
                                <th scope="col" className='text-center'>Action</th>                                                      
                              </tr>
                            </thead>
                            <tbody>
                              {CorSerPackageList.map((item, index) => (
                               
                                <tr key={index}>
                                  <td className='text-center'>{index + 1}</td>
                                  <td>{item.ServiceTitle}</td>
                                  <td className='text-center'>{item.ServicesPackageType}</td>
                                  <td className='text-center'>{item.PlanAmount}</td>
                                  <td className='text-center'>{item.TotalAmount}</td>
                                  <td className='text-center'>{item.TotalRecivedAmount}</td>
                                  <td className='text-center'>{item.TotalDueAmount}</td>
                                  <td>{item.BArray.map((item2, idx) => (<a className="btn btn-primary btn-sm font-14 btncolor Bureaubtn" onClick={ManageRedirection.bind(this,item,item2)} key={idx}>{item2.VTitle}</a>))}</td>
                            
                                  {/* <a onClick={(ManageServiceActivitiesRecord.bind(this, item.ServicesPackageId, item.CorporateleadId))} className="btn btn-primary btn-sm font-14 btncolor" > Manage Bureau</a>
                            */}
                                 
                                </tr>
                              ))}
                         

                            </tbody>
                          </table>
                        </div>


                        
                </div>

              </div>
            </div>

          </div>

       
        </div>
      </div>
    </React.Fragment>
  );
};

export default Services;
