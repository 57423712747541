import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label } from "reactstrap";
import SendIcon from '@mui/icons-material/Send';
import { RestaurantMenu } from "@mui/icons-material";

const UiRating = () => {
  const [CorporateClientActivitiesStatus, setCorporateClientActivitiesStatus] = useState('Pending');
  const [BureauDetail, setBureauDetail] = useState([]);
  const [BureauDueDetail, setBureauDueDetail] = useState([]);
  const [BureauStepDetail, setBureauStepDetail] = useState([]);
  const [BureauStepActivitiesDetail, setBureauStepActivitiesDetail] = useState([]);
  const [BureauStepActivitiesActionGroupBy, setBureauStepActivitiesActionGroupBy] = useState([]);
  const [BureauStepActivitiesActionDetail, setBureauStepActivitiesActionDetail] = useState([]);
  const [BureauStepActivitiesStatus, setBureauStepActivitiesStatus] = useState([]);
  const [BureauStepActivitiesFile, setBureauStepActivitiesFile] = useState([]);
  const [Message, setMessage] = useState([]);
  const [MessageFileName, setMessageFileName] = useState("");
  const [MessageFilePath, setMessageFilePath] = useState("");
  const [MessageFileType, setMessageFileType] = useState("");
  const [MessageList, setMessageList] = useState([]);
  const [SenderId, setSenderId] = useState(localStorage.getItem("CorporateleadId"));
  const history = useHistory();
  const textareaRef = useRef(null);

  const [DocumentFilePath, setDocumentFilePath] = useState('');
  const [EditRowId, setEditRowId] = useState(0);

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + "px";
  }, [Message]);


  useEffect(() => {
    let CorporateLeadId = localStorage.getItem("CorporateleadId") == null ? '0' : localStorage.getItem("CorporateleadId") == '' ? '0' : localStorage.getItem("CorporateleadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");

    if (CorporateLeadId != null && CorporateServiceId != null) {
      if (CorporateLeadId != "0") {
        getServicePackageActivities();
        getCorporateLeadServiceStatus();
        getMessageList();
      }
      else {
        history.push("/SerPackageDetail");
      }
    }
    else {
      history.push("/SerPackageDetail");
    }
  }

    , []);

  const getServicePackageActivities = async () => {

    let CorporateLeadId = localStorage.getItem("CorporateleadId") == null ? '0' : localStorage.getItem("CorporateleadId") == '' ? '0' : localStorage.getItem("CorporateleadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let VBureauId = localStorage.getItem("CorporateServiceBureauId") == null ? '0' : localStorage.getItem("CorporateServiceBureauId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");

    const RequestArr = {
      ServiceId: CorporateServiceId,
      CorporateLeadId: CorporateLeadId,
      BureauId: VBureauId,
      BureauStepId: CorporateServiceBureauStepId,
      PaymentDueId: CorporateServiceBureauDueId
    };

    console.log("RequestArr----", RequestArr);

    var AUrl = global.APIURL + '/CorporateLeadServiceActivities';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        console.log("CSPres", CSPres);
        if (CSPres.data.Result.length > 0) {

          setBureauStepDetail(CSPres.data.Result[0].BureauStepDetail);
          setBureauStepActivitiesDetail(CSPres.data.Result[0].BureauStepActivitiesDetail);
          setBureauStepActivitiesActionGroupBy(CSPres.data.Result[0].BureauStepActivitiesActionGroupBy)
          setBureauStepActivitiesActionDetail(CSPres.data.Result[0].BureauStepActivitiesActionDetail);
          setBureauStepActivitiesStatus(CSPres.data.Result[0].BureauStepActivitiesActionStatus)

        }

      });
  };

  const getServicePackageActivitiesAction = async () => {
    let CorporateLeadId = localStorage.getItem("CorporateleadId") == null ? '0' : localStorage.getItem("CorporateleadId") == '' ? '0' : localStorage.getItem("CorporateleadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauId = localStorage.getItem("CorporateServiceBureauId") == null ? '0' : localStorage.getItem("CorporateServiceBureauId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");


    BureauStepActivitiesActionGroupBy.length = 0;
    BureauStepActivitiesActionDetail.length = 0;
    BureauStepActivitiesStatus.length = 0;

    const RequestArr = {
      ServiceId: CorporateServiceId,
      CorporateLeadId: CorporateLeadId,
      BureauId: CorporateServiceBureauId,
      BureauStepId: CorporateServiceBureauStepId
    };
    var AUrl = global.APIURL + '/CorporateLeadServiceActivitiesAction';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        if (CSPres.data.Result.length > 0) {
          setBureauStepActivitiesActionGroupBy(CSPres.data.Result[0].BureauStepActivitiesActionGroupBy)
          setBureauStepActivitiesActionDetail(CSPres.data.Result[0].BureauStepActivitiesActionDetail);
          setBureauStepActivitiesStatus(CSPres.data.Result[0].BureauStepActivitiesActionStatus)
        }
        else {
          BureauStepActivitiesActionGroupBy.length = 0;
          BureauStepActivitiesActionDetail.length = 0;
          BureauStepActivitiesStatus.length = 0;
        }
      });
  };

  const onClickActivitiesAction = async (Vitem) => {

    console.log("Vitem", Vitem);
    let BureauStepActivitiesDetailArr = BureauStepActivitiesDetail.filter(x => x.MasterId == Vitem.Id);
    let VAddButtonControlId = document.getElementById(Vitem.AddButtonControlId).innerHTML;
    let VHdfRowIndexId = document.getElementById(Vitem.AddRowCountControlId).value;
    let CorporateLeadId = localStorage.getItem("CorporateleadId") == null ? '0' : localStorage.getItem("CorporateleadId") == '' ? '0' : localStorage.getItem("CorporateleadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");
    let VLoginType = localStorage.getItem('LoginType') == null ? '0' : localStorage.getItem('LoginType') == "" ? '0' : localStorage.getItem('LoginType');
    let VLoginUser = localStorage.getItem("LoginName") == null ? '0' : localStorage.getItem('LoginName') == "" ? '0' : localStorage.getItem('LoginName');

    if (VAddButtonControlId == "Save") {
      for (let bsa = 0; bsa < BureauStepActivitiesDetailArr.length; bsa++) {

        const VBureauStepActivitiesDetail = BureauStepActivitiesDetailArr[bsa];
        var VActionValue = '';
        let BureauStepActivitiesActionGroupByArr = BureauStepActivitiesActionGroupBy.filter(x => x.BureauId == Vitem.BureauId);
        BureauStepActivitiesActionGroupByArr = BureauStepActivitiesActionGroupByArr.filter(x => x.PaymentDueId == CorporateServiceBureauDueId);
        BureauStepActivitiesActionGroupByArr = BureauStepActivitiesActionGroupByArr.filter(x => x.ServiceActivitiesId == Vitem.Id);
        BureauStepActivitiesActionGroupByArr = BureauStepActivitiesActionGroupByArr.sort((a, b) => (a.RowIndexId - b.RowIndexId) ? -1 : 1);
        let LastIndexId = (BureauStepActivitiesActionGroupByArr.length == 0 ? 0 : BureauStepActivitiesActionGroupByArr[0].RowIndexId) + 1;
        var VActionType = VBureauStepActivitiesDetail.ActionType;
        var VDocFileName = '';
        if (VActionType == 'File') {
          let VDocumentFilepath = DocumentFilePath;
          var filearray = VDocumentFilepath.toString().split('/');
          VDocFileName = filearray.length > 4 ? filearray[5] : '';
        }
        else {
          VActionValue = document.getElementById(VBureauStepActivitiesDetail.FormControlId).value == null ? '' : document.getElementById(VBureauStepActivitiesDetail.FormControlId).value;
        }

        const ActivitiesActionArr = {
          Id: 0,
          RowIndexId: LastIndexId,
          BureauId: Vitem.BureauId,
          BureauStepId: CorporateServiceBureauStepId,
          CorporateLeadId: CorporateLeadId,
          ServiceId: CorporateServiceId,
          PaymentDueId: CorporateServiceBureauDueId,
          ServiceActivitiesId: Vitem.Id,
          ServiceActivitiesActionId: VBureauStepActivitiesDetail.Id,
          ActionTitle: VBureauStepActivitiesDetail.ActionTitle,
          ActionValue: VBureauStepActivitiesDetail.ActionType == 'File' ? DocumentFilePath : VActionValue,
          ActionUploadFileName: VBureauStepActivitiesDetail.ActionType == 'File' ? VDocFileName : '',
          ActionType: VBureauStepActivitiesDetail.ActionType,
          ActionValueDate: VBureauStepActivitiesDetail.ActionType == 'Date' ? VActionValue == '' ? '1990-01-01' : VActionValue : '1990-01-01',
          ServiceActivitiesActionStausId: VBureauStepActivitiesDetail.ActionStatusId,
          CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),
          UserType: VLoginType,
          UserName: VLoginUser
        }
        console.log("save", ActivitiesActionArr);
        axios.post(global.APIURL + '/CorporateLeadServiceActivitiesActionSave', ActivitiesActionArr).then(res => { });

        if (bsa == (BureauStepActivitiesDetailArr.length - 1)) {
          alert('Corporate service activities action save successfully');
          setDocumentFilePath('');
          setEditRowId(0);
          document.getElementById(Vitem.AddRowCountControlId).value = '0';
          getBureauStepStatus(Vitem.BureauId, CorporateServiceBureauDueId, Vitem.Id, Vitem.ActivitiesStatusControlId);
          getCorporateLeadServiceStatus();
          getServicePackageActivities();
          getServicePackageActivitiesAction();
        }
        document.getElementById(VBureauStepActivitiesDetail.FormControlId).value = '';
      }
    }
    else if (VAddButtonControlId == "Update") {
      var VBureauStepActivitiesActionDetail = BureauStepActivitiesActionDetail.filter(x => x.BureauId == Vitem.BureauId && x.PaymentDueId == CorporateServiceBureauDueId && x.ServiceActivitiesId == Vitem.Id && x.RowIndexId == VHdfRowIndexId);
      for (let bsu = 0; bsu < VBureauStepActivitiesActionDetail.length; bsu++) {
        const VBSActivitiesActionDetail = VBureauStepActivitiesActionDetail[bsu];
        var VActionType = VBSActivitiesActionDetail.ActionType;
        var VActionValue = '';
        if (VActionType == 'File') {
          VActionValue = DocumentFilePath;
          let VDocumentFilepath = DocumentFilePath;
          var filearray = VDocumentFilepath.toString().split('/');
          VDocFileName = filearray.length > 4 ? filearray[5] : '';
        }
        else {
          VActionValue = document.getElementById(VBSActivitiesActionDetail.FormControlId).value == null ? '' : document.getElementById(VBSActivitiesActionDetail.FormControlId).value;
        }

        const ActivitiesActionArr = {
          Id: VBSActivitiesActionDetail.Id,
          ActionValue: VActionValue,
          ActionUploadFileName: VActionType == 'File' ? VDocFileName : '',
          ActionType: VBSActivitiesActionDetail.ActionType,
          ActionValueDate: VBSActivitiesActionDetail.ActionType == 'Date' ? VActionValue == '' ? '1990-01-01' : VActionValue : '1990-01-01',
          ActionStausId: VActionValue == null ? parseInt(0) : VActionValue == '' ? parseInt(0) : VBSActivitiesActionDetail.ServiceActivitiesActionStausId,
          CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),
          UserType: VLoginType,
          UserName: VLoginUser
        }
        axios.post(global.APIURL + '/CorporateLeadServiceActivitiesActionUpdate', ActivitiesActionArr).then(res => { });

        if (bsu == (VBureauStepActivitiesActionDetail.length - 1)) {
          alert('Corporate service activities action update successfully');
          setDocumentFilePath('');
          setEditRowId(0);
          getBureauStepStatus(Vitem.BureauId, CorporateServiceBureauDueId, Vitem.Id, Vitem.ActivitiesStatusControlId);
          getCorporateLeadServiceStatus();
          document.getElementById(Vitem.AddRowCountControlId).value = '0';
          getServicePackageActivities();
          getServicePackageActivitiesAction();
        }

        document.getElementById(VBSActivitiesActionDetail.FormControlId).value = '';
      }
      document.getElementById(Vitem.AddButtonControlId).innerHTML = "Save";
    }
  };

  const onClickActivitiesCancelAction = async (Vitem2) => {

    let BureauStepActivitiesDetailArr = BureauStepActivitiesDetail.filter(x => x.MasterId == Vitem2.Id);

    for (let bsa = 0; bsa < BureauStepActivitiesDetailArr.length; bsa++) {

      const VBureauStepActivitiesDetail = BureauStepActivitiesDetailArr[bsa];

      document.getElementById(VBureauStepActivitiesDetail.FormControlId).value = '';
    }

  };

  const GetActionStatusCode = async (Vitem2, VADItem) => {
    let CorporateLeadId = localStorage.getItem("CorporateleadId") == null ? '0' : localStorage.getItem("CorporateleadId") == '' ? '0' : localStorage.getItem("CorporateleadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");

    const ActivitiesActionArr = {
      BureauId: Vitem2.BureauId,
      CorporateLeadId: CorporateLeadId,
      ServiceId: CorporateServiceId,
      PaymentDueId: CorporateServiceBureauDueId,
      ServiceActivitiesId: VADItem.MasterId,
      ServiceActivitiesActionId: VADItem.Id,
      ServiceActivitiesActionStausId: VADItem.ActionStatusId,
      ServiceActivitiesActionStausCode: VADItem.ActionStatusCode,
      ServiceActivitiesActionStausTitle: VADItem.ActionStatusTitle
    }
    BureauStepActivitiesStatus.push(ActivitiesActionArr);

    var VBureauStepActivitiesLastCode = BureauStepActivitiesStatus.filter(x => x.BureauId == Vitem2.BureauId && x.CorporateLeadId == CorporateLeadId);
    VBureauStepActivitiesLastCode = VBureauStepActivitiesLastCode.filter(x => x.ServiceId == CorporateServiceId && x.PaymentDueId == CorporateServiceBureauDueId);
    VBureauStepActivitiesLastCode = VBureauStepActivitiesLastCode.filter(x => x.ServiceActivitiesId == VADItem.MasterId);
    const FAS = VBureauStepActivitiesLastCode.sort((a, b) => (b.ServiceActivitiesActionStausCode - a.ServiceActivitiesActionStausCode));
    var LastFinalStatusCode = FAS[0].ServiceActivitiesActionStausCode + ' - ' + FAS[0].ServiceActivitiesActionStausTitle;
    document.getElementById(Vitem2.ActivitiesStatusControlId).value = LastFinalStatusCode;

  }

  const EditActivitiesAction = async (item3, item4) => {

    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");
    var VBureauStepActivitiesActionDetail = BureauStepActivitiesActionDetail.filter(x => x.BureauId == item3.BureauId && x.PaymentDueId == CorporateServiceBureauDueId && x.ServiceActivitiesId == item4.ServiceActivitiesId && x.RowIndexId == item4.RowIndexId);

    console.log("VBureauStepActivitiesActionDetail", VBureauStepActivitiesActionDetail);

    for (let bsa = 0; bsa < VBureauStepActivitiesActionDetail.length; bsa++) {
      const BureauStepActivitiesActionDetail = VBureauStepActivitiesActionDetail[bsa];
      var FCId = BureauStepActivitiesActionDetail.FormControlId;
      var VActionType = BureauStepActivitiesActionDetail.ActionType;
      if (VActionType == 'File') {
        setDocumentFilePath(BureauStepActivitiesActionDetail.ActionValue);
      }
      else {
        document.getElementById(FCId).value = BureauStepActivitiesActionDetail.ActionValue;
      }
    }
    document.getElementById(item3.AddButtonControlId).innerHTML = "Update";
    var VRowIndexId = item3.AddRowCountControlId;
    document.getElementById(VRowIndexId).value = item4.RowIndexId;
    var RId = BureauStepActivitiesActionGroupBy.RowIndexId;
    setEditRowId(RId);
  }

  const DeleteActivitiesAction = async (BureauStepDetail, BureauStepActivitiesActionGroupBy) => {
    let CorporateLeadId = localStorage.getItem("CorporateleadId") == null ? '0' : localStorage.getItem("CorporateleadId") == '' ? '0' : localStorage.getItem("CorporateleadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");
    // let VPaymentDueId = localStorage.getItem("CorporateServiceDueId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");


    const ActivitiesActionArr = {
      RowIndexId: BureauStepActivitiesActionGroupBy.RowIndexId,
      BureauId: BureauStepDetail.BureauId,
      BureauStepId: CorporateServiceBureauStepId,
      CorporateLeadId: CorporateLeadId,
      ServiceId: CorporateServiceId,
      PaymentDueId: CorporateServiceBureauDueId,
      ServiceActivitiesId: BureauStepDetail.Id,
      CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")
    }
    axios.post(global.APIURL + '/CorporateLeadServiceActivitiesActionDelete', ActivitiesActionArr)
      .then(res => {
        alert('Corporate service activities action delete successfully.');
        // window.location.reload();
        getServicePackageActivities();
      });
  }

  const getBureauStepStatus = async (BureauId, PaymentDueId, ServiceActivitiesId, ActivitiesStatusControlId) => {
    let CorporateLeadId = localStorage.getItem("CorporateleadId") == null ? '0' : localStorage.getItem("CorporateleadId") == '' ? '0' : localStorage.getItem("CorporateleadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");

    const RequestArr = {
      ServiceId: CorporateServiceId,
      CorporateLeadId: CorporateLeadId,
      BureauId: BureauId,
      BureauStepId: CorporateServiceBureauStepId,
      PaymentDueId: PaymentDueId,
      ServiceActivitiesId: ServiceActivitiesId
    };
    var AUrl = global.APIURL + '/CorporateLeadServiceActivitiesLastStatus';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        if (CSPres.data.Result.length > 0) {
          var LastFinalStatusCode = CSPres.data.Result[0].ServiceActivitiesLastStatus[0].ServiceActivitiesStatus;
          document.getElementById(ActivitiesStatusControlId).value = LastFinalStatusCode;
        }
      });
  };

  const getCorporateLeadServiceStatus = async () => {
    let CorporateLeadId = localStorage.getItem("CorporateleadId") == null ? '0' : localStorage.getItem("CorporateleadId") == '' ? '0' : localStorage.getItem("CorporateleadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");
    let CorporateServiceBureauId = localStorage.getItem("CorporateServiceBureauId") == null ? '0' : localStorage.getItem("CorporateServiceBureauId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauId");
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");

    const RequestArr = {
      ServiceId: CorporateServiceId,
      CorporateLeadId: CorporateLeadId,
      BureauId: CorporateServiceBureauId,
      BureauStepId: CorporateServiceBureauStepId,
      PaymentDueId: CorporateServiceBureauDueId,
      ServiceActivitiesId: 0
    };
    var AUrl = global.APIURL + '/CorporateLeadServiceLastStatus';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        console.log("CSPres", CSPres);
        if (CSPres.data.Result.length > 0) {
          var LastFinalStatusCode = CSPres.data.Result[0].ServiceActivitiesLastStatus[0].ServiceActivitiesStatus;
          document.getElementById('txtCorporateClientActivitiesStatus').value = LastFinalStatusCode;
        }
      });
  };

  const onClickActivitiesFile = async (ActivitiesId) => {
    const RequestArr = {
      ServiceActivitiesId: ActivitiesId
    };
    var AUrl = global.APIURL + '/CorporateServiceActivitiesFiles';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        setBureauStepActivitiesFile(CSPres.data);
        if (CSPres.data.length > 0) {
          $('#mpreferencedocuments').modal('show');
        }
        else {
          $('#mpreferencedocuments').modal('hide');
        }
      });
  };

  const getAttachFile = (e) => {

    var details = e.target.files[0];
    console.log("details", details.name);
    var FileType = details.name.toString().split(".");
    var details1 = "UserChatFile";

    const formData = new FormData();

    formData.append('Document', details);
    formData.append('FolderName', details1);


    axios.post(global.APIURL + '/uploadavatar', formData, {

      headers: { "Content-Type": details.type },

    }).then((res) => {

      setMessageFileName(res.data.FileName);
      setMessageFilePath(res.data.IName);
      setMessageFileType(FileType[1]);

    });
  }

  const sendMessage = async () => {

    let VSenderId = localStorage.getItem("CorporateleadId") == null ? '0' : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId");

    let isSend = 1;
    if (Message == "") {

      isSend = 0;
    }

    if (isSend == 1) {

      const sendData = {
        Id: 0,
        SenderId: VSenderId,
        SubSenderId: VSenderId,
        SenderType: 'C',
        ReceiverId: localStorage.getItem("AssignManagerId") == null ? "0" : localStorage.getItem("AssignManagerId") == "" ? "0" : localStorage.getItem("AssignManagerId"),
        SubReceiverId: localStorage.getItem("AssignManagerId") == null ? "0" : localStorage.getItem("AssignManagerId") == "" ? "0" : localStorage.getItem("AssignManagerId"),
        ReceiverType: 'U',
        MessageText: Message,
        MessageFileName: MessageFileName,
        MessageFilePath: MessageFilePath,
        MessageFileType: MessageFileType,
        CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")
      }
      console.log("sendData", sendData);

      axios.post(global.APIURL + '/UserChatMessageSave', sendData).then(res => {
        console.log("res-msg", res);
        getMessageList();
        document.getElementById("txtMessage").value = "";


      });
    }
    else {
      alert("Input Message");
    }

  }

  const getMessageList = async () => {

    let VSenderId = localStorage.getItem("CorporateleadId");

    const Mdata = {
      SenderId: VSenderId,
      SenderType: 'C',
      ReceiverId: localStorage.getItem("AssignManagerId") == null ? "0" : localStorage.getItem("AssignManagerId") == "" ? "0" : localStorage.getItem("AssignManagerId"),
      ReceiverType: 'U'
    }

    axios.post(global.APIURL + '/UserChatMessageSelectAll', Mdata).then(res => {
      console.log(res);
      setMessageList(res.data);

    });

  }

  const getRefreshList = async () => {
    getMessageList();
  }

  const scrollwindow = () => {
    modalbody.scrollTo(0, 13000);
  }

  const getDocumentpath = async (e) => {
    var details = e.target.files[0];
    var details1 = "CorporateServiceActivitiesDocument";
    var filename = details.name;
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData, {
      headers: { "Content-Type": details.type },
    })
      .then((res) => {
        setDocumentFilePath(res.data.IName);
      });
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client Service Activities | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">
          <div className="modal fade bd-example-modal-lg" id="mpreferencedocuments" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Reference Documents List</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">  </button>
                </div>
                <div className="modal-body">
                  <Row className="me-0">
                    {BureauStepActivitiesFile.map((item, idx) => (
                      <Col className="col-lg-6 col-md-6 " key={idx}>
                        <div className="bureaustepactivitiesfile">
                          <a href={item.FilePath} target="_blank" rel="noopener noreferrer" className="fadocumenttitleiconcolor">
                            <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true" ></i>{item.FileName}
                          </a>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <div className="modal right fade fadenew" id="myModal2" tabIndex="-1" aria-labelledby="exampleModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable"   >
              <div className="modal-content" >
                <div className="modal-header messageheader">
                  <Row className="mb-1">
                    <div className="col-lg-10">
                      <h5 className="modal-title msgfont " id="exampleModalLabel">Message Box</h5></div>
                    <div className="col-lg-2 d-flex">
                      <a className="btn btn_success btn-sm font-14 me-0 refreshbtn" onClick={e => getRefreshList()}>  <i className="fa-solid fa-rotate"></i></a>
                      <a className="btn btn_success btn-sm font-14 me-0 closebtn " data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></a>

                    </div>

                  </Row>
                </div>

                <div className="modal-body">
                  <Row className="mb-1">
                    <div className="col-lg-10">
                      <textarea ref={textareaRef} rows="1" type="text" className="form-control" placeholder="Type a message" id="txtMessage" onChange={(e) => setMessage(e.target.value)} />
                    </div>
                    <div className="col-lg-2 d-flex justify-content-end">
                      <a className="btn btn_success btn-sm font-14 me-2 btncolor" id="btnAttachFile"><i className="mdi mdi-attachment font-size-18" ></i></a>
                      <a className="btn btn_success btn-sm font-14 me-0 btncolor" id="lnkSendMessage" onClick={sendMessage}><i className="mdi mdi-telegram font-size-18"></i></a>

                      <input type="file" id="btnTriggerFile" className="d-none" onChange={(e) => getAttachFile(e)} />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-12">

                      <div className="mainhightnewedit" id="mainhightnewedit">


                        {MessageList.map((item, idx) => (
                          item.SenderType == 'C' ?
                            <div className="chatborderend mb-1" key={idx}   >
                              <div className="mt-1" >
                                <p className="chatlistclass me-2 mb-2">{item.MessageText}</p>
                                {item.MessageFilePathFlag == "A" ?
                                  item.MessageFileType == "JPEG" ?
                                    <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer">   <img className="chatimagefileclass mb-2 me-2" src={item.MessageFilePath}></img></a>
                                    :
                                    item.MessageFileType == "JPG" ?
                                      <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer">   <img className="chatimagefileclass mb-2 me-2" src={item.MessageFilePath}></img></a>
                                      :
                                      item.MessageFileType == "PNG" ?
                                        <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"> <img className="chatimagefileclass mb-2 me-2" src={item.MessageFilePath}></img></a>
                                        :
                                        item.MessageFileType == "GIF" ?
                                          <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"> <img className="chatimagefileclass mb-2 me-2" src={item.MessageFilePath}></img></a>
                                          :
                                          <p className="chatlistclass newfilenameditname me-2"><a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><i className="mdi mdi-folder-download font-size-18" aria-hidden="true" ></i> {item.MessageFileName}</a></p>
                                  :
                                  ""
                                }
                                <p className="chattimeclass me-2">{item.SendMessageDateTime}</p>
                              </div>
                            </div>
                            :
                            <div className="d-flex chatborder mb-1">
                              <div className="me-2 ms-2 mt-1">
                                <img className="rounded-circle chat-profile-user" src="/static/media/avatar-1.c69c03fa.jpg" alt="Header Avatar" />
                              </div>
                              <div className="mt-1">
                                <p className="chatlistclass"><b>{item.SenderName}</b></p>
                                <p className="chatlistclass mb-2">{item.MessageText}</p>
                                {item.MessageFilePathFlag == "A" ?
                                  item.MessageFileType == "JPEG" ?
                                    <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer">   <img className="chatimagefileclass mb-2" src={item.MessageFilePath}></img></a>
                                    :
                                    item.MessageFileType == "JPG" ?
                                      <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer">   <img className="chatimagefileclass mb-2" src={item.MessageFilePath}></img></a>
                                      :
                                      item.MessageFileType == "PNG" ?
                                        <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer">   <img className="chatimagefileclass mb-2" src={item.MessageFilePath}></img></a>
                                        :
                                        item.MessageFileType == "GIF" ?
                                          <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer">       <img className="chatimagefileclass mb-2" src={item.MessageFilePath}></img></a>
                                          :
                                          <p className="chatlistclass newfilenameditname"><a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><i className="mdi mdi-folder-download font-size-18" aria-hidden="true" ></i> {item.MessageFileName}</a></p>
                                  :
                                  ""
                                }
                                <p className="chattimeclass" >{item.SendMessageDateTime}</p>
                              </div>
                            </div>
                        ))}


                      </div>

                    </div>

                    <div>


                    </div>
                  </Row>




                </div>


              </div>
            </div>

          </div>
          <Row className="mb-3">
            <Col className="col-lg-5 col-md-6 col-12">
              <div className="breadcrum ps-0">
                <CardTitle>Service Activities</CardTitle>
                <a href="/SerPackageDetail" className="me-2 accordfontcolor">Service Details</a>{" "}
                <span>/</span>
                <a href="/" className="me-2 ms-2 accordfontcolor">Service Activities</a>{" "}
              </div>
            </Col>
            <Col className="col-lg-9 col-md-6 col-12 mt-3 mt-md-0 ">
              <div className="d-flex align-items-end justify-content-end">
                <div className="me-2">
                  <p className="mb-2 fontWeight500">Corporate Client Activities Status</p>
                  <Input type="text" readOnly className="" id="txtCorporateClientActivitiesStatus" />
                </div>
                <div>
                  <a
                    className="btn btn-primary  font-14 btncolor  me-2 "
                    href="/ServiceMonthlyDues"
                  >
                    <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                  </a>
                  <a
                    className="btn btn-primary  font-14 btncolor   " data-bs-toggle="modal" data-bs-target="#myModal2"
                  >
                    <i className="fas fa-sliders-h align-middle me-1"></i>Message
                  </a>
                </div>

              </div>
            </Col>




            {/* <Col className="col-lg-3 col-md-6 col-12 mt-3 mt-md-0">
              <a className="btn btn-secondary btn-sm font-14 me-2 chatcss msgbtn" data-bs-toggle="modal" data-bs-target="#myModal2" >
                <i className="fas fa-sliders-h align-middle me-1 msgfont"></i>Message
              </a>
            </Col> */}

          </Row>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="ind-card" id="ind-card">
                <Row>
                  <Col className="col-lg-12">
                    <Card>
                      <CardBody>
                        <div className="accordion " id="accordionExample">
                          {BureauStepDetail.map(item2 => (
                            <div className="accordion-item" key={item2.RowId}>
                              <h2 className="accordion-header accordbgcolor" id={item2.AccordionId1}>
                                <button className={item2.RowId == 1 ? 'accordion-button' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target={item2.collapse2} aria-expanded={item2.RowId == 1 ? 'true' : 'false'} aria-controls={item2.collapse1}>
                                  <Row className="duerowclass">
                                    <Col className="col-lg-4">{item2.Title}{" "}</Col>
                                    <Col className="col-lg-4 duedateclass"></Col>
                                    <Col className="col-lg-4 duestatusclass"> <input type="text" id={item2.ActivitiesStatusControlId} defaultValue={item2.ActivitiesStatusCode == null ? 'Pending' : item2.ActivitiesStatusCode} className="text-danger activity-status fw-bold textborderhide" aria-describedby="emailHelp" /></Col>
                                  </Row>
                                </button>
                              </h2>
                              <div id={item2.collapse1} className={item2.RowId == 1 ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} aria-labelledby={item2.AccordionId1} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <Row>
                                    <Col className="col-lg-9 mb-1">
                                      <b><u>Description :</u></b>
                                    </Col>
                                    {item2.ServiceActivitiesFilesCount > 0 ?
                                      <Col className="col-lg-3 mb-1 duestatusclass">
                                        <b><u><a className="referencedocumentsclass" onClick={e => onClickActivitiesFile(item2.Id)}><i className="fas fa-eye ms-2 fasfaeye"></i><span className="ms-1">Reference Documents</span></a></u></b>
                                      </Col>
                                      : ''}
                                    <Col className="col-lg-12">
                                      {item2.Description}
                                    </Col>
                                    <Col className="col-lg-12 mt-3 mb-3">
                                      <hr />
                                    </Col>
                                  </Row>
                                  <Row>
                                    {BureauStepActivitiesDetail.filter(x => x.MasterId == item2.Id).map(ADitem => (
                                      <div className="col-md-6 col-lg-3 col-12" key={ADitem.RowId}>
                                        <form>
                                          <div className="mb-3">
                                            <label htmlFor={ADitem.FormControlId} className="form-label">{ADitem.ActionTitle}</label>
                                            {ADitem.ActionType == 'Date'
                                              ?
                                              <input type="date" id={ADitem.FormControlId} className="form-control" aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item2, ADitem)} />
                                              :
                                              ADitem.ActionType == 'Text'
                                                ?
                                                <input type="text" className="form-control" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item2, ADitem)} />
                                                :
                                                ADitem.ActionType == 'TextArea'
                                                  ?
                                                  <textarea type="" rows="1" className="form-control" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item2, ADitem)} />
                                                  :
                                                  ADitem.ActionType == 'Number'
                                                    ?
                                                    <input type="number" className="form-control" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item2, ADitem)} />
                                                    :
                                                    ADitem.ActionType == 'File'
                                                      ?
                                                      <input type="file" className="form-control" placeholder={ADitem.FormControlPlaceholder}  aria-describedby="emailHelp" onChange={getDocumentpath} />
                                                      :
                                                      ''
                                            }
                                          </div>
                                        </form>
                                      </div>
                                    ))}
                                  </Row>
                                  <Row>
                                    <div className="col-lg-4 co-md-6 col-12 mb-3">
                                      <button className="btn btn_success btn-sm font-14 me-2 btncolor" id={item2.AddButtonControlId} onClick={e => onClickActivitiesAction(item2)}>Save</button>
                                      <button className="btn btn_danger btn-sm  font-14" id={item2.CancelButtonControlId} onClick={e => onClickActivitiesCancelAction(item2)}>Cancel</button>
                                      <input type="hidden" id={item2.AddRowCountControlId} value="0"></input>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <hr />
                                      <CardTitle>Action Log</CardTitle>
                                      <hr />
                                    </Col>
                                    <div className="col-lg-12 mt-3">
                                      <div className="table-responsive">
                                        <table className="table table-bordered align-middle whitenowrapedit">
                                          <thead>
                                            <tr>
                                              <th className="textaligncenter">#</th>
                                              {BureauStepActivitiesDetail.filter(x => x.MasterId == item2.Id).map(ADitem => (
                                                <th scope="col" key={ADitem.RowId}>{ADitem.ActionTitle}</th>
                                              ))}
                                              <th className="textaligncenter">Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {BureauStepActivitiesActionGroupBy.filter(x => x.ServiceActivitiesId == item2.Id).map((rcitem, idx) => (
                                              <tr key={idx}>
                                                <td scope="col" className="text-center">{idx + 1}</td>
                                                {BureauStepActivitiesActionDetail.filter(x => x.ServiceActivitiesId == item2.Id && x.RowIndexId == rcitem.RowIndexId).sort((a, b) => (a.ServiceActivitiesActionId - b.ServiceActivitiesActionId)).map((rcisubtem, idx1) => (
                                                  <td scope="col" key={idx1}>
                                                    {rcisubtem.ActionType == 'Date' ? rcisubtem.SActionValueDate : rcisubtem.ActionType == 'File' ? <a href={rcisubtem.ActionValue} target="_blank" rel="noopener noreferrer">{rcisubtem.ActionUploadFileName}</a> : rcisubtem.ActionValue}
                                                  </td>
                                                ))}
                                                <td className="textaligncenter">
                                                  <button className="text-primary deletebuttonclass" onClick={e => EditActivitiesAction(item2, rcitem)}><i className="mdi mdi-pencil font-size-18"></i></button>
                                                  <button className="text-danger deletebuttonclass" onClick={e => DeleteActivitiesAction(item2, rcitem)}><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}
export default UiRating;
