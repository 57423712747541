import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import Commheader from "../../../components/HorizontalLayout/Header";
import PlacholderImgC from "assets/images/favicon.png";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";


const ServiceMonthlyDues = () => {
  const history = useHistory();
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CompanyCityId, setCompanyCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Branchcode, setBranchcode] = useState("");
  const [CompanyList, setCompanyList] = useState([]);
  const [NetworkSource, setNetworkSource] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkType, setNetworkType] = useState("");
  const [DistrictName, setDistrictName] = useState([]);
  const [TehsilName, setTehsilName] = useState([]);
  const [CityName, setCityName] = useState([]);

  const [LeadContactList, setLeadContactList] = useState([]);
  const [Address, setAddress] = useState("");
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [StateName, setStateName] = useState("");
  const [EditcorLeadId, setcorEditLeadId] = useState("");
  const [ServiceTitleList, setServiceTitleList] = useState([]);


  const [Email, setEmail] = useState([]);
  const [Name, setName] = useState([]);
  const [MobileNo, setMobileNo] = useState([]);
  const [Designation, setDesignation] = useState([]);

  const [MeetNote, setMeetNote] = useState([]);
  const [MeetSummary, setMeetSummary] = useState([]);

  const [EmailIdd, setmEmailId] = useState([]);
  const [SerPackageList, setSerPackageList] = useState([]);
  const [ClientList, setClientList] = useState([]);
  const [BureauList, setBureauList] = useState([]);
  const [ServiceMonthlyDuesList, setServiceMonthlyDuesList] = useState([]);
  const [CorporateServicesPackageBureauStepList, setCorporateServicesPackageBureauStepList] = useState([]);
  const [CorporateServicesPackageBureauDueList, setCorporateServicesPackageBureauDueList] = useState([]);
  const [CorporateSerPackBureauDueList, setCorporateSerPackBureauDueList] = useState([]);
  const [CorSerPackageList, setCorSerPackageList] = useState([]);
  const [ServiceMonthlyDue, setServiceMonthlyDue] = useState([]);
  const [CorporateServicesPackageList, setCorporateServicesPackageList] = useState([]);
  const [CorporateServicesPackageBureau, setCorporateServicesPackageBureau] = useState([]);

  let CoLeadId = localStorage.getItem("CorporateleadId");

  const VSerPackageId = localStorage.getItem("CorporateServiceId");

  useEffect(() => {

    getServiceDetails();
    getCompanyList();
    getNetWorkList();
    getSerPackageDetails();
    getBureauList();
    getCorporateServicesPackageList();
    getManageServiceActivitiesBureauDueRecord();

  }, []);

  const getServiceDetails = async () => {
    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const SData = {
      "Id": VCorporateleadId
    }

    axios.post(global.APIURL + '/CorporateleadclientDetails ', SData)
      .then(res => {
        console.log("res00", res);

        setClientList(res.data.Result[0].ClientDetails);
        setNetworkSource(res.data.Result[0].ClientDetails[0].CompanyName);
        setNetworkType(res.data.Result[0].ClientDetails[0].ChannelTypeName);
        setAddress(res.data.Result[0].ClientDetails[0].Address);
        setStateName(res.data.Result[0].ClientDetails[0].StateName);
        setDistrictName(res.data.Result[0].ClientDetails[0].districtName);
        setTehsilName(res.data.Result[0].ClientDetails[0].TehsilName);
        setCityName(res.data.Result[0].ClientDetails[0].CityName);
        setPincode(res.data.Result[0].ClientDetails[0].Pincode);
        setBranchcode(res.data.Result[0].ClientDetails[0].Branchcode);
        setmEmailId(res.data.Result[0].ClientDetails[0].EmailId);
        setLeadContactList(res.data.Result[0].de);
      });
  }

  const getSerPackageDetails = () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const SData = {
      "Id": VCorporateleadId,
      "ServicesPackageId": VSerPackageId
    }
    console.log("SData", SData);
    axios.post(global.APIURL + '/CorporateLeadServicepaymentDetails ', SData)
      .then(res => {

        setSerPackageList(res.data.Result[0].ServiceDetails);
        setServiceTitleList(res.data.Result[0].Servicetitle);

      });

  }

  const getManageServiceActivitiesBureauDueRecord = async () => {
    let VCorporateLeadId = parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"));
    let VCorporateServiceId = parseInt(localStorage.getItem("CorporateServiceId") == null ? "0" : localStorage.getItem("CorporateServiceId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
    let VCorporateServiceBureauId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
    const UserDetailArr = {
      CorporateLeadId: VCorporateLeadId,
      ServiceId: VCorporateServiceId,
      BureauId: VCorporateServiceBureauId,
      BureauStepId: 0
    }
    var AUrl = global.APIURL + '/CorporateServiceActivitiesBureauDue';
    axios.post(AUrl, UserDetailArr)
      .then(res => {
        console.log("res2", res);
        setCorporateServicesPackageBureauDueList(res.data);

        const CArray = [];
        for (let i = 0; i < res.data.length; i++) {
          let DateConvert = moment(res.data[i].DueDate, 'DD-MM-YYYY').format('MM-DD-YYYY');
          let VDateConvert = moment(DateConvert).format('MMMM', 'YYYY');
          let VYear = moment(res.data[i].DueDate, 'DD-MM-YYYY').format('YYYY');

          const CItem = {
            VRowId: res.data[i].RowId,
            VDueTitle: res.data[i].DueTitle,
            VDueDate: res.data[i].DueDate,
            VDueDateConvert: VDateConvert,
            VYear: VYear,
            VIsAccess: res.data[i].IsAccess,
            VDueStatus: res.data[i].DueStatus,
            VCorporateleadId: res.data[i].CorporateleadId,
            VServicesPackageId: res.data[i].ServicesPackageId,
            VId: res.data[i].Id,
            VAmount: res.data[i].Amount,
            VGstAmount: res.data[i].GstAmount,
            VTotalAmount: res.data[i].TotalAmount,
            VGstPercentage: res.data[i].GstPercentage,
            VServiceActivitiesStatus: res.data[i].ServiceActivitiesStatus
          }
          CArray.push(CItem);
        }
        setCorporateSerPackBureauDueList(CArray);
      });
  }

  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };

  const getNetWorkList = async () => {
    var ACUrl = global.APIURL + '/ChannalTypeList/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setNetworkTypeList(CityL);
      });
  };

  const exportPDF = () => {

    const title = "Client Service Details";

    const header = [[{ content: 'Corporate Information', colSpan: 10, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Network Source", "Network Type", "Email", "Address", "State", "District", "Tehsil", "City", "Pincode", "Branch code"]];
    const data = ClientList.map((item, index) => [index + 1, item.CompanyName, item.ChannelTypeName, item.EmailId, item.Address, item.StateName, item.districtName, item.TehsilName, item.CityName, item.Pincode, item.Branchcode]);
    let content = {
      startY: 50,
      head: header,
      body: data,
      headStyles: { fillColor: ['#2ca67a'] },
    };

    const header1 = [[{ content: 'Service Package Details', colSpan: 7, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Service Title", "Type", "Price", "Total Amount", "Recived Amount", "Due Amount"]];
    const data1 = ServiceTitleList.map((item, index) => [index + 1, item.ServiceTitle, item.ServicesPackageType, item.PlanAmount, item.TotalAmount, item.TotalRecivedAmount, item.TotalDueAmount]);
    let content1 = {

      head: header1,
      body: data1,
      headStyles: { fillColor: ['#2ca67a'] },
      // styles: {fontSize: 14 , fontFamily:"Hebrew",fontStyle:"bold"}
      //alternateRowStyles: {fillColor : [209,225,249]}
    };

    const header2 = [[{ content: 'Payment Details', colSpan: 7, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Service Title", "Type", "DueDate", "Amount", "Gst Amount", "TotalAmount", "PaymentStatus"]];
    const data2 = SerPackageList.map((item, index) => [index + 1, item.ServiceTitle, item.ServicesPackageType, item.DueDate, item.Amount, item.GstAmount, item.TotalAmount, item.PaymentStatus]);
    let content2 = {

      head: header2,
      body: data2,
      headStyles: { fillColor: ['#2ca67a'] },
      // styles: {fontSize: 14 , fontFamily:"Hebrew",fontStyle:"bold"}
      //alternateRowStyles: {fillColor : [209,225,249]}
    };


    var doc = new jsPDF('p', 'pt');
    doc.text(title, 40, 40);
    doc.autoTable(content);
    doc.autoTable(content1);
    doc.autoTable(content2);

    doc.save("ClientService.pdf")

  }

  const getBureauList = async () => {
    let VCorporateleadId = localStorage.getItem("CorporateleadId");
    const CData = {
      ServiceId: VSerPackageId,
      CorporateLeadId: VCorporateleadId
    }
    console.log("CData", CData);
    axios.post(global.APIURL + '/CorporateServiceActivitiesBureau ', CData)
      .then(res => {
        console.log("res", res)
        setBureauList(res.data);

      });

  }

  const inputHandler = e => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  const getMonthlyPayDetails = (Vitem) => {
    let VDueDate = Vitem.VDueDate;
    console.log("Vitem", Vitem);

    let MonthlyPayArray = [];
    MonthlyPayArray = CorporateSerPackBureauDueList.filter(x => x.VDueDate == VDueDate);
    console.log("Monthdata", MonthlyPayArray);
    setServiceMonthlyDue(MonthlyPayArray);

    let VCorporateLeadId = parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"));
    let VCorporateServiceId = parseInt(localStorage.getItem("CorporateServiceId") == null ? "0" : localStorage.getItem("CorporateServiceId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
    let VBId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"));
    localStorage.setItem("CorporateServiceBureauDueId", parseInt(Vitem.VId == null ? '0' : Vitem.VId == '' ? '0' : Vitem.VId));

    const UserDetailArr = {
      CorporateLeadId: VCorporateLeadId,
      ServiceId: VCorporateServiceId,
      BureauId: parseInt(VBId == null ? '0' : VBId == '' ? '0' : VBId),
    }
    console.log("UserDetailArr", UserDetailArr);
    var AUrl = global.APIURL + '/CorporateServiceActivitiesBureauStep';
    axios.post(AUrl, UserDetailArr)
      .then(res => {
        console.log("res-Steps", res);
        setCorporateServicesPackageBureauStepList(res.data);
      });
  }

  const getCorporateServicesPackageList = async () => {
    let VSerPackageDetails = parseInt(localStorage.getItem("CorporateServicePackageId") == null ? "0" : localStorage.getItem("CorporateServicePackageId") == "" ? "0" : localStorage.getItem("CorporateServicePackageId"));
    let VBId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"));
    let CorporateLeadId = parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"));
    const CorporateData = {
      Id: CorporateLeadId,
    }
    axios.post(global.APIURL + '/CorporateServicesPackageBureau', CorporateData)
      .then(res => {
        console.log("resss", res);

        setCorporateServicesPackageList(res.data.ServicesPackage);
        setCorporateServicesPackageBureau(res.data.ServicesPackageBureau);
        let SArray = [];

        let VCorporateServicesPackageList = [];
        VCorporateServicesPackageList = res.data.ServicesPackage.filter(x => x.Id == VSerPackageDetails);

        for (let i = 0; i < VCorporateServicesPackageList.length; i++) {


          console.log("VCorporateServicesPackageList", VCorporateServicesPackageList);

          for (let j = 0; j < VCorporateServicesPackageList.length; j++) {

            let VSerPackageId = VCorporateServicesPackageList[j].ServicesPackageId;
            let SerBureauList = [];
            SerBureauList = res.data.ServicesPackageBureau.filter(x => x.ServiceId == VSerPackageId && x.BId == VBId);
            console.log("SerBureauList", SerBureauList);

            let BArray = [];

            for (let k = 0; k < SerBureauList.length; k++) {
              const SItem = {
                VRowId: SerBureauList[k].RowId,
                VBId: SerBureauList[k].BId,
                VServiceId: SerBureauList[k].ServiceId,
                VTitle: SerBureauList[k].Title,
                VCorporateLeadId: SerBureauList[k].CorporateLeadId
              }
              BArray.push(SItem);
            }

            const SItem2 = {
              Id: VCorporateServicesPackageList[j].Id,
              CorporateleadId: VCorporateServicesPackageList[j].CorporateleadId,
              ServicesPackageId: VCorporateServicesPackageList[j].ServicesPackageId,
              ServicesPackageType: VCorporateServicesPackageList[j].ServicesPackageType,
              PlanAmount: VCorporateServicesPackageList[j].PlanAmount,
              TotalAmount: VCorporateServicesPackageList[j].TotalAmount,
              TotalRecivedAmount: VCorporateServicesPackageList[j].TotalRecivedAmount,
              TotalDueAmount: VCorporateServicesPackageList[j].TotalDueAmount,
              PaymentStaus: VCorporateServicesPackageList[j].PaymentStaus,
              PaymentType: VCorporateServicesPackageList[j].PaymentType,
              ServiceTitle: VCorporateServicesPackageList[j].ServiceTitle,
              BArray: BArray
            }
            SArray.push(SItem2);
            setCorSerPackageList(SArray);
          }
        }
      });
  }
  console.log("CorSerPackageList", CorSerPackageList);

  const ManageServiceActivitiesBureauDueActionRecord = (item) => {
    localStorage.setItem("CorporateServiceBureauStepId", parseInt(item.Id == null ? '0' : item.Id == '' ? '0' : item.Id))
    window.location.href = "/CorporateClientServiceActivities";
  }

  const FutureDueClicked = () => {
    alert("You can Manage Activities of Paid Month Only");
  }


  return (
    <React.Fragment>
      <Commheader />

      <div className="page-content">
        <MetaTags>
          <title>Corporate Service Detail | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">
          <table id="emp" className="table" hidden >
            <thead>
              <tr><td></td>
                <td></td>
                <td></td>
                <td><h2>Corporate Details</h2></td>
                <td></td>
                <td></td>
              </tr>
              <tr></tr>
              <tr>
                <th>#</th>
                <th>Network Source</th>
                <th>Network Type</th>
                <th>Email</th>
                <th>Address</th>
                <th>State</th>
                <th>District</th>
                <th>Tehsil</th>
                <th>City</th>
                <th>Pincode</th>
                <th>Branch code</th>
              </tr>
            </thead>
            <tbody>

              {
                ClientList.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td>{item.CompanyName}</td>
                    <td>{item.ChannelTypeName}</td>
                    <td>{item.EmailId}</td>
                    <td>{item.Address}</td>
                    <td>{item.StateName}</td>
                    <td>{item.districtName}</td>
                    <td>{item.TehsilName}</td>
                    <td>{item.CityName}</td>
                    <td>{item.Pincode}</td>
                    <td>{item.Branchcode}</td>


                  </tr>

                })
              }
              <tr><td></td></tr>
              <tr><td></td></tr>

              <tr><td></td>
                <td></td>
                <td></td>

                <td><h2>Service</h2></td>
                <td></td>
                <td></td>
              </tr>
              <tr><td></td></tr>
            </tbody>
            <thead>
              <tr>
                <th>#</th>
                <th>Service Title</th>
                <th>Type</th>
                <th>Price</th>
                <th>Total Amount</th>
                <th>Total Received Amount</th>
                <th>Total Due Amount</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                ServiceTitleList.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>

                    <td>{item.ServiceTitle}</td>
                    <td>{item.ServicesPackageType}</td>
                    <td>{item.PlanAmount}</td>
                    <td>{item.TotalAmount}</td>
                    <td>{item.TotalRecivedAmount}</td>
                    <td>{item.TotalDueAmount}</td>


                  </tr>
                })
              }
              <tr><td></td></tr>
              <tr><td></td></tr>

              <tr><td></td>
                <td></td>
                <td></td>
                <td><h2>Payment</h2></td>
                <td></td>
                <td></td>
              </tr>
              <tr><td></td></tr>

            </tbody>
            <thead>
              <tr>
                <th>#</th>
                <th>Service Title</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Gst Amount</th>
                <th>Total Amount</th>
                <th>Due Date</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <tbody>



              {
                SerPackageList.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>

                    <td>{item.ServiceTitle}</td>
                    <td>{item.ServicesPackageType}</td>
                    <td>{item.Amount}</td>
                    <td>{item.GstAmount}</td>
                    <td>{item.TotalAmount}</td>
                    <td>{item.DueDate}</td>
                    <td>{item.PaymentStatus}</td>


                  </tr>
                })
              }

            </tbody>

          </table>
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Corporate Service Monthly Dues</CardTitle>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14 btncolor "
                href="/Services"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
              <ReactHtmlTableToExcel
                className="btn btn-primary btn-sm float-end font-14 me-2 btncolor"
                table="emp"
                filename="ClientService"
                sheet="Sheet"
                buttonText="Export Excel" />

              {<button className="btn btn-primary btn-sm float-end font-14 me-2 btncolor" onClick={exportPDF}>Export PDF</button>}
            </Col>
          </Row>
          <Row>
            <Row>

            </Row>
          </Row>
          <div className="ind-card" id="ind-card">
            <Row>
              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12 mb-2 ">
                        {CorSerPackageList.map((item, idx) => (<Col className="col-lg-12 col-md-12 mb-2 spkgtitle" key={idx}>
                          <CardTitle>{item.ServiceTitle}{item.BArray.map((item2, idx) => (<span key={idx}>{"  "}({item2.VTitle})</span>))}</CardTitle>
                        </Col>))}
                      </Col>
                      <div className="row">
                        <div className="card-body">
                          <p className="maincommontitlefordahboard sdash"></p>
                          {CorSerPackageList.map((item, idx) => (<div className="row" key={idx}>
                            <div className="col-md-4" >
                              <div className="ccboxmaindashb">
                                <div className="d-flex ccdetailscall mb-1">
                                  <div>
                                    <p className="mb-0">Service Package Details</p>
                                  </div>
                                </div>
                                <div className="ccboxlinetext"></div>
                                <div className="d-flex sercss spackage">
                                  <div>
                                    <i className="fa-solid fa-cube"></i>   <p className="ciboxtitlegrid pkgcss pkgtitlecss1">Package Type</p><p className="pkgcss pkgdetailcss">{item.ServicesPackageType}</p>
                                  </div>
                                </div>
                                <div className="d-flex sercss spackage">
                                  <div>
                                    <i className="fa-sharp fa-solid fa-tag"></i>  <p className="ciboxtitlegrid pkgcss pkgtitlecss2">Plan Amount</p><p className="pkgcss pkgdetailcss2">{item.PlanAmount}</p>
                                  </div>
                                </div>
                                <div className="d-flex sercss spackage">
                                  <div>
                                    <i className="fa-sharp fa-solid fa-wallet"></i>   <p className="ciboxtitlegrid pkgcss pkgtitlecss3">Total Amount</p><p className="pkgcss pkgdetailcss3">{item.TotalAmount}</p>
                                  </div>
                                </div>
                                <div className="d-flex sercss spackage">
                                  <div>
                                    <i className="fa-solid fa-hand-holding-dollar"></i>  <p className="ciboxtitlegrid pkgcss pkgtitlecss4">Total Received Amount</p><p className="pkgcss pkgdetailcss4">{item.TotalRecivedAmount}</p>
                                  </div>
                                </div>
                                <div className="d-flex spackage spackage">
                                  <div>
                                    <i className="fa-solid fa-circle-dollar-to-slot"></i>  <p className="ciboxtitlegrid pkgcss pkgtitlecss5">Total Due Amount</p><p className="pkgcss pkgdetailcss5">{item.TotalDueAmount}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>))}
                        </div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      {/* <Col className="col-lg-12 col-md-12 col-12 mb-2">
                      { CorSerPackageList.map((item, idx) => (<Col className="col-lg-12 col-md-12 mb-2" key={idx}>
                      <CardTitle>{item.ServiceTitle}{item.BArray.map((item2, idx) => (<span key={idx}>{"  "}({item2.VTitle})</span>))}</CardTitle>
                  
                    </Col>))}
                        <hr />
                      </Col>
                     */}
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Type </p>
                        <Input
                          type="text"
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={NetworkType}
                          readOnly

                        />

                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Source </p>
                        <Input
                          type="text"

                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={NetworkSource}
                          readOnly

                        />

                      </Col>
                      <Col>
                        <div className="col-lg-6 col-md-6 col-12 mt-3">
                          <label htmlFor="EmailId" className="form-label">
                            EmailId
                          </label>
                          <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={EmailIdd}
                            readOnly />
                        </div>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                        <p className="mt-3 mb-2 fontWeight500"> Address </p>
                        <div className="input-group auth-pass-inputgroup">
                          <textarea className="form-control" readOnly placeholder="Address" id="txtIndAddress" rows="3" name="txtIndAddress" defaultValue={Address} onChange={e => setAddress(e.target.value)}></textarea>
                        </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> State </p>
                        <Input
                          type="text"
                          readOnly
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={StateName} />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> District </p>
                        <Input
                          type="text"
                          readOnly
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={DistrictName}
                        />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Tehsil </p>
                        <Input
                          type="text"
                          readOnly
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={TehsilName}
                        />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> City/Village/Town </p>
                        <Input
                          type="text"
                          readOnly
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={CityName}
                        />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PinCode </p>
                        <Input type="number" className="" readOnly id="txtPinCode" defaultValue={Pincode} onChange={e => setPincode(e.target.value)} placeholder="Enter PinCode" onKeyPress={inputHandler}
                          maxlength="6" />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Branch Code </p>
                        <Input type="text" className="" readOnly id="txtBranchCode" defaultValue={Branchcode} onChange={e => setBranchCode(e.target.value)} placeholder="Enter Branch Code" />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <CardTitle>Due Months</CardTitle>
                      </Col>
                    </Row>
                    <Row>
                      {CorporateSerPackBureauDueList?.map((item, idx) => (
                        <div className="col-md-2 mb-2" key={idx}>
                          <a className="btn btn-primary btn-sm font-14 me-2 btncolor btnwidth Monthbtn" onClick={getMonthlyPayDetails.bind(this, item)}>{item.VDueDateConvert}{" "}{item.VYear}</a>
                        </div>
                      ))}
                    </Row>
                  </CardBody>
                </Card>
                {ServiceMonthlyDue.length > 0 ?
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="col-lg-12 col-md-12 mb-2">
                          <CardTitle>Services Package Month Due</CardTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-lg-12 col-md-12 mb-2">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col" className='text-center'>Title</th>
                                <th scope="col" className='text-center'>Due Date</th>
                                <th scope="col" className='text-center'>Amount</th>
                                <th scope="col" className='text-center'>Gst Amount</th>
                                <th scope="col" className='text-center'>Total Amount</th>
                                <th scope="col" className='text-center'>Payment Status</th>
                                <th scope="col" className='text-center'>Status</th>

                              </tr>
                            </thead>
                            <tbody>
                              {ServiceMonthlyDue?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td className='text-center'>{item.VDueTitle}</td>
                                  <td className='text-center'>{item.VDueDate}</td>
                                  <td className='text-center'>{item.VAmount}</td>
                                  <td className='text-center'>{item.VGstAmount} ({item.GstPercentage}%)</td>
                                  <td className='text-center'>{item.VTotalAmount}</td>
                                  <td className='text-center'>{item.VDueStatus}</td>
                                  <td className='text-center'>{item.VServiceActivitiesStatus}</td>

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  : ''}

                <Col className="col-lg-12 col-md-12 col-12">
                  {CorporateServicesPackageBureauStepList.length > 0 ?
                    <Card>
                      <CardBody>
                        <Row>
                          <Col className="col-lg-12 col-md-12 mb-2">
                            <CardTitle>Services Package Bureau Step</CardTitle>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-lg-12 col-md-12 mb-2">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col" className='text-center'>#</th>
                                  <th scope="col" className='text-center'>Title</th>
                                  <th scope="col" className='text-center'>Description</th>
                                  <th scope="col" className='text-center'>Status</th>
                                  <th className="text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {CorporateServicesPackageBureauStepList?.map((item, idx) => (
                                  <tr key={idx}>
                                    <td className='text-center'>{idx + 1}</td>
                                    <td className='text-center'>{item.Title}</td>
                                    <td className='text-center'>{item.Description}</td>
                                    <td className='text-center'>{item.ServiceActivitiesStatus}</td>
                                    <td className='text-center'>
                                      <a className="btn btn-primary btn-sm font-14 btncolor me-2" onClick={ManageServiceActivitiesBureauDueActionRecord.bind(this, item)}> Manage </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    : ''}
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ServiceMonthlyDues;
