import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";

import axios from 'axios'

const Navbar = () => {
  const [DashboardClass, setDashboardClass] = useState('nav-item dropdown')
  const [ManageofMeetingClass, setManageofMeetingClass] = useState('nav-item dropdown')
  const [BureauHelp, setBureauHelp] = useState('nav-item dropdown');
  const [Services, setServices] = useState('nav-item dropdown');
  const [MenuList, setMenuList] = useState([]);

  const [BureauList, setBureauList] = useState([])
  const [BureauHelpLinkList, setBureauHelpLinkList] = useState([])

  useEffect(() => {
    getBureauHelpData();
    getMenuList();
    if (window.location.pathname == '/dashboard') {
      setDashboardClass('nav-item dropdown active');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
    }
    else if (window.location.pathname == '/CorContactInfo/CorLeadMomList') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown active');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
    }
    else if (window.location.pathname == '/CorContactInfo/BureauHelp') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown active');
      setServices('nav-item dropdown');
    }
    else if (window.location.pathname == '/CorContactInfo/BureauHelpLink') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown active');
      setServices('nav-item dropdown');
    }
    else if (window.location.pathname == '/Services/ServiceDetail') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown active');
    }
    else if (window.location.pathname == '/ServiceMonthlyDues') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown active');
    }
    else {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
    }
  }, []);

  const getBureauHelpData = () => {
    axios.post(global.APIURL + '/BureauHelpLinkList')
      .then(res => {
        if (res.data.length > 0) {
          setBureauList(res.data.BureauList);
          setBureauHelpLinkList(res.data.BureauHelpLinkList);
        }
      });
  }

  const getMenuList = async () => {
    let CorporateLeadId = localStorage.getItem("CorporateleadId");
    const CorporateData = {
      Id: CorporateLeadId,
    }
    await axios.post(global.APIURL + '/CorporateServicesPackageBureau', CorporateData)
      .then(res => {
        // setCorporateServicesPackageList(res.data.ServicesPackage);
        // setCorporateServicesPackageBureau(res.data.ServicesPackageBureau);
        let SArray = [];
        for (let i = 0; i < res.data.ServicesPackage.length; i++) {
          let VCorporateServicesPackageList = res.data.ServicesPackage[i];
          let VSerPackageId = VCorporateServicesPackageList.ServicesPackageId;
          let SerBureauList = [];
          SerBureauList = res.data.ServicesPackageBureau.filter(x => x.ServiceId == VSerPackageId);
          let BArray = [];
          for (let j = 0; j < SerBureauList.length; j++) {
            const SItem = {
              VBId: SerBureauList[j].BId,
              VServiceId: SerBureauList[j].ServiceId,
              VTitle: SerBureauList[j].Title
            }
            BArray.push(SItem);
          }
          const SItem2 = {
            VId: VCorporateServicesPackageList.Id,
            CorporateleadId: res.data.ServicesPackage[i].CorporateleadId,
            ServicesPackageId: res.data.ServicesPackage[i].ServicesPackageId,
            ServicesPackageType: res.data.ServicesPackage[i].ServicesPackageType,
            PlanAmount: res.data.ServicesPackage[i].PlanAmount,
            TotalAmount: res.data.ServicesPackage[i].TotalAmount,
            TotalRecivedAmount: res.data.ServicesPackage[i].TotalRecivedAmount,
            TotalDueAmount: res.data.ServicesPackage[i].TotalDueAmount,
            PaymentStaus: res.data.ServicesPackage[i].PaymentStaus,
            PaymentType: res.data.ServicesPackage[i].PaymentType,
            ServiceTitle: res.data.ServicesPackage[i].ServiceTitle,
            BArray: BArray
          }
          SArray.push(SItem2);
          setMenuList(SArray);
        }
      });
  }

  const PageLinkClick = async (PageLink) => {
    if (PageLink == 'Dashboard') {
      setDashboardClass('nav-item dropdown active');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
      window.location.href = "/dashboard";
    }
    else if (PageLink == 'ManageofMeeting') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown active');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
      window.location.href = "/CorContactInfo/CorLeadMomList";
    }
    else if (PageLink == 'CorLeadMomList') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown active');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
      window.location.href = "/CorContactInfo/CorLeadMomList";
    }
    else if (PageLink == 'BureauHelp') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown active');
      setServices('nav-item dropdown');
      window.location.href = "/CorContactInfo/BureauHelp";
    }
    else if (PageLink == 'BureauHelpLink') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown active');
      setServices('nav-item dropdown');
      window.location.href = "/CorContactInfo/BureauHelpLink";
    }
    else if (PageLink == 'ServicePackageStep') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      // setBureauHelp('nav-item dropdown');
      // setBureauHelpLink('nav-item dropdown');
      setServices('nav-item dropdown active');
      window.location.href = "/Services";
    }
    else {
      setDashboardClass('nav-item dropdown active');
      setManageofMeetingClass('nav-item dropdown');
      // setBureauHelp('nav-item dropdown');
      // setBureauHelpLink('nav-item dropdown');
      setServices('nav-item dropdown');
      window.location.href = "/dashboard";
    }
  }

  const ManageRedirection = (item) => {
    //console.log(item);
    // console.log(item.VBId);
    // console.log(item.VServiceId);
    localStorage.setItem("CorServiceBureauId", parseInt(item.VBId == null ? '0' : item.VBId == '' ? '0' : item.VBId));
    localStorage.setItem("CorServicePackageId", parseInt(item.VServiceId == null ? '0' : item.VServiceId == '' ? '0' : item.VServiceId));
    window.location.href = "/Services/ServiceDetail";

  }
  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">


          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              className="navbar-collapse"
              id="topnav-menu-content"
            >

              <ul className="navbar-nav">

                <li className={DashboardClass} id="lidashboard">
                  <a className="nav-link dropdown-toggle arrow-none" onClick={e => PageLinkClick('Dashboard')} >Dashboard</a>
                </li>
                <li className={ManageofMeetingClass} id="liCorLeadMomList">
                  <a className="nav-link dropdown-toggle arrow-none" onClick={e => PageLinkClick('ManageofMeeting')}>Manage of Meeting</a>
                </li>
                {MenuList?.map((item, idx) => (
                  <li key={idx} className={Services} id="liServicePackageStep">
                    <a className="nav-link dropdown-toggle arrow-none" onClick={e => PageLinkClick('ServicePackageStep')}>{item.ServiceTitle} <div className="arrow-down"></div></a>
                    <div className="dropdown-menu">
                      {item.BArray.map((item2, idx1) => (
                        <a key={idx1} className="dropdown-item" onClick={ManageRedirection.bind(this, item2)}>{item2.VTitle}</a>
                      ))}
                    </div>
                  </li>
                ))}
                <li className={BureauHelp} id="liBureauHelp">
                  <a className="nav-link dropdown-toggle arrow-none">Help Link <div className="arrow-down"></div></a>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" onClick={e => PageLinkClick('BureauHelp')}>Bureau Help</a>
                    {BureauList.length > 0 ? <a className="dropdown-item" onClick={e => PageLinkClick('BureauHelpLink')}>Bureau Help Link</a> : ''}
                  </div>
                </li>
                {/* <li className={BureauHelp} id="liBureauHelp">
                  <a className="nav-link dropdown-toggle arrow-none" onClick={e => PageLinkClick('BureauHelp')}>Bureau Help</a>
                </li>
                
                <li className={BureauHelpLink} id="liBureauHelpLink">
                  <a className="nav-link dropdown-toggle arrow-none" onClick={e => PageLinkClick('BureauHelpLink')}>Bureau Help Link</a>
                </li>
                <li className={Services} id="liServices">
                  <a className="nav-link dropdown-toggle arrow-none" onClick={e => PageLinkClick('Services')}>Services</a>
                </li> */}

              </ul>


            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Navbar;
