import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'


import axios from 'axios'
//import Apaexlinecolumn from "pages/AllCharts/apex/apaexlinecolumn";
import ReactApexChart from "react-apexcharts"
import e from 'cors';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const BureauHelp = () => {

    const [BureauCategoryList, setBureauCategoryList] = useState([])
    const [BureauSubCategoryList, setBureauSubCategoryList] = useState([])
    const [BureauHelpList, setBureauHelpList] = useState([])

    useEffect(() => {
        getBureauHelpData();
    }, []);

    const getBureauHelpData = () => {
        const CorporateData = { Id: '0' }
        axios.post(global.APIURL + '/CorporateBureauHelpList', CorporateData)
            .then(res => {
                if (res.data.Result.length>0) {
                    setBureauCategoryList(res.data.Result[0].BureauCategory);
                    setBureauSubCategoryList(res.data.Result[0].BureauSubCategory);
                    setBureauHelpList(res.data.Result[0].BureauHelp);
                }
            });
    }
    const exportPDF = () => {

        const title = "Bureau SubCategory List";


        const header = [[{ content: 'Corporate Information', colSpan: 5, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "CLevel", "Name", "EmailId", "ContactNo"]];
        const data = BureauHelpList.map((item, index) => [index + 1, item.CLevel, item.Name, item.EmailId, item.ContactNo]);
        let content = {
            startY: 50,
            head: header,
            body: data,
            headStyles: { fillColor: ['#2ca67a'] },
        };


        doc.text(title, 40, 40);
        doc.autoTable(content);


        doc.save("BureauCategory.pdf")

    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | KC Corporate Admin</title>
                </MetaTags>
                <div className="container-fluid">
                    <Row className="mb-3">
                        <Col className="col-lg-8 col-md-6 col-6">
                            <div className="breadcrum ps-0">
                                <CardTitle className="mb-0">Bureau Help</CardTitle>
                            </div>
                        </Col>
                        <Col className="col-lg-4 co-md-6 col-6">
                        </Col>
                    </Row>
                    <Row>
                        {BureauCategoryList.map((item, idx) => (
                            <Col className={BureauCategoryList.length == 1 ? "col-12" : "col-6"} key={idx}>
                                <Card className="heighfixdashbiardnew">
                                    <CardBody>
                                        <Row>
                                            <div className="col-lg-12 mb-2 col"><div className="card-title newtitaleeditburee"> {item.Title}  </div></div>
                                            <Col xl="12">
                                                {BureauSubCategoryList.filter(x => x.BId == item.BId).map((item1, idx1) => (
                                                    <div className="mainnewcategeroiesbureu mb-3" key={idx1} id="bureautable">
                                                        <div className="maincategeriesnamebox ps-2 pe-2 pt-2 pb-2 ">
                                                            <h6 className="mb-0 maincategeriesnamenewedit">{item1.Title}</h6>
                                                            <hr />
                                                        </div>
                                                        <div className="maindatauserbuera">
                                                            <div className="maindatdestailsbureau" >
                                                                <div className="">
                                                                    <p className="maintitlebureau text-center">#</p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="maintitlebureau text-center">Communication Level</p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="maintitlebureau">Name</p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="maintitlebureau">Email & Mobile No</p>
                                                                </div>
                                                            </div>
                                                            {BureauHelpList.filter(x => x.BId == item.BId && x.BsubId == item1.BsubId).map((item2, idx2) => (
                                                                <div className="maindatdestailsbureau mt-2" key={idx2}>
                                                                    <div className="">
                                                                        <h6 className="mb-0 text-center">{idx2 + 1}</h6>
                                                                    </div>
                                                                    <div className="">
                                                                        <h6 className="mb-0 text-center">{item2.CLevel}</h6>
                                                                    </div>
                                                                    <div className="">
                                                                        <h6 className="mb-0">{item2.Name}</h6>
                                                                    </div>
                                                                    <div className="">
                                                                        <h6 className="mb-0">{item2.EmailId}<br />{item2.ContactNo}</h6>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                </div>
            </div>

        </React.Fragment>
    );

}

export default BureauHelp;
