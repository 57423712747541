import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';

const AddBranch = () => {
    const [ServiceStatusList, setServiceStatusList] = useState([]);
    const [UploadMonthList, setUploadMonthList] = useState([]);
    const [UploadFileList, setUploadFileList] = useState([]);
    const [CurrentUploadMonthTitle, setCurrentUploadMonthTitle] = useState('');
    const [UploadMonth, setUploadMonth] = useState('');
    const [UploadDate, setUploadDate] = useState('');
    const [UploadStatusId, setUploadStatusId] = useState(0);
    const MonthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    useEffect(() => {
        var today = new Date();
        var CurrentYear = today.getFullYear().toString();
        var VUploadMonthTitle = MonthNames[today.getMonth()] + '-' + CurrentYear;
        setCurrentUploadMonthTitle(VUploadMonthTitle);
        if (UploadMonthList.length == 0) {
            const UploadMonthItem = {
                UploadMonthTitle: VUploadMonthTitle == null ? "" : VUploadMonthTitle
            };
            UploadMonthList.push(UploadMonthItem);
        }

        
        setUploadMonth(VUploadMonthTitle);
        getServiceStatusList();
    }, []);

    const getServiceStatusList = async () => {
        await axios.post(global.APIURL + '/ServiceStatusList')
            .then(res => {
                setServiceStatusList(res.data);
            });
    }

    function DocumentfetchPost(e) {
        for (let index = 0; index < e.target.files.length; index++) {
            var DocumentFile = e.target.files[index];
            var FolderName = "ServiceDocument";
            const formData = new FormData();
            formData.append('Document', DocumentFile);
            formData.append('FolderName', FolderName);
            var AUrl = global.APIURL + '/uploadavatar/';
            axios.post(AUrl, formData, { headers: { "Content-Type": DocumentFile.type }, })
                .then((res) => {
                    const DocumentFileDetail = {
                        FilePath: res.data.IName == null ? "" : res.data.IName,
                        FileName: res.data.FileName == null ? "" : res.data.FileName
                    };
                    UploadFileList.push(DocumentFileDetail);
                });
        }
    }

    const SaveServiceDetailType = async (e) => {
        e.preventDefault();
        console.log(UploadFileList);
        var VCorServiceReportType=localStorage.getItem("CorServiceReportType") == null ? "" : localStorage.getItem("CorServiceReportType") == "" ? "" : localStorage.getItem("CorServiceReportType");
        var VReportUploadType=localStorage.getItem("ReportUploadType") == null ? "" : localStorage.getItem("ReportUploadType") == "" ? "" : localStorage.getItem("ReportUploadType");
        var VReportTitle=VCorServiceReportType+" "+VReportUploadType+" "+UploadMonth;
        const SaveServiceDetailTypeItem = {
            CorporateLeadId: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
            CorporateLeadUserId: parseInt(localStorage.getItem("CorporateleadContactId") == null ? "0" : localStorage.getItem("CorporateleadContactId") == "" ? "0" : localStorage.getItem("CorporateleadContactId")),
            ServicePackageId: parseInt(localStorage.getItem("CorServicePackageId") == null ? "0" : localStorage.getItem("CorServicePackageId") == "" ? "0" : localStorage.getItem("CorServicePackageId")),
            BureauId: parseInt(localStorage.getItem("CorServiceBureauId") == null ? "0" : localStorage.getItem("CorServiceBureauId") == "" ? "0" : localStorage.getItem("CorServiceBureauId")),
            FinancialYearId: parseInt(localStorage.getItem("FinancialYearId") == null ? "0" : localStorage.getItem("FinancialYearId") == "" ? "0" : localStorage.getItem("FinancialYearId")),
            UploadStep: parseInt(localStorage.getItem("CorServiceUploadStep") == null ? "0" : localStorage.getItem("CorServiceUploadStep") == "" ? "0" : localStorage.getItem("CorServiceUploadStep")),
            ReportType: VCorServiceReportType,
            ReportTitle: VReportTitle,
            ReportUploadType: VReportUploadType,
            UploadMonth: UploadMonth,
            UploadDate: UploadDate,
            UploadStatusId: UploadStatusId,
            CreateUser: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
        }

        axios.post(global.APIURL + "/CorporateServiceUploadReportSave", SaveServiceDetailTypeItem)
            .then(res => {
                var VRecordInsertId = res.data[0].RecordInsertId;
                var rowcount=0;
                for (let con = 0; con < UploadFileList.length; con++) {
                    const UploadFileDetail = UploadFileList[con];
                    var VFilePath = UploadFileDetail.FilePath;
                    if (VFilePath != null) {
                        if (VFilePath != '') {
                            const SaveServiceDetailTypeItemArr = {
                                MasterId: VRecordInsertId,
                                UploadFilePath: VFilePath,
                                UploadFileName: UploadFileDetail.FileName,
                                CreateUser: parseInt(localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")),
                            };

                            axios.post(global.APIURL + '/CorporateServiceUploadReportFileSave', SaveServiceDetailTypeItemArr).then(() => { });
                        }
                    }

                    rowcount++;
                }

                if(UploadFileList.length==rowcount)
                {
                    localStorage.setItem("CorServiceReportType",'');
                    localStorage.setItem("CorServiceUploadStep",'0');
                    localStorage.setItem("CorServiceUploadStepMasterId", '0');
                    localStorage.setItem("ReportUploadType",'');
                    alert('Report upload save successfully.');
                    window.location.href = "/Services/ServiceDetail";
                }
            });
    }

    return (
        <div className="page-content">

            <MetaTags>
                <title>Branch | KC Corporate Admin</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-6">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Add Branch</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/Branch" className="ms-2 me-2">Branch</a>
                            <span>/</span>
                            <span className="ms-2">Add Branch</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-6">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/Branch">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>


                                <Row>

                                    {/* <Col lg={2}>
                                        <label className="form-label">Report Upload Type</label>
                                        <select className="form-select" name="ddlReportUploadType" onChange={(e) => setReportUploadType(e.target.value)}>
                                            <option value={"Credit Info Upload"}> Credit Info Upload</option>
                                            <option value={"OLM Upload"}> OLM Upload</option>
                                        </select>
                                    </Col> */}
                                    <Col lg={2}>
                                        <label className="form-label">Upload Month</label>
                                        <select className="form-select" name="ddlStatus" onChange={(e) => setUploadMonth(e.target.value)}>
                                            {UploadMonthList.map((item) => (
                                                <option key={item.UploadMonthTitle} value={item.UploadMonthTitle} selected={CurrentUploadMonthTitle == item.UploadMonthTitle}>
                                                    {item.UploadMonthTitle}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col lg={2}>
                                        <div className="mb-2">
                                            <label className="form-label">Upload Date</label>
                                            <Input type="date" className="form-control" id="txtUploadDate" onChange={(e) => setUploadDate(e.target.value)} />
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="mb-2">
                                            <label className="form-label">Upload File</label>
                                            <input type="file" className="form-control form-control" multiple id="FUDocumentFilePath" onChange={DocumentfetchPost} />
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <label className="form-label">Status</label>
                                        <select className="form-select" name="ddlStatus" onChange={(e) => setUploadStatusId(e.target.value)}>
                                            <option value={"0"}>Select Status</option>
                                            {ServiceStatusList.map((item) => (
                                                <option key={item.Id} value={item.Id}>
                                                    {item.StatusTitle}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col lg={12} className="mt-3">
                                        <button className="btn btn_success btn-sm" id="btnsave" onClick={e => SaveServiceDetailType(e)} >Save</button>
                                        <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default AddBranch;